import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { ViewColumn } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import {
    DataGridPro,
    GridFilterModel,
    GridFilterPanel,
    GridRowId,
    GridRowSelectionModel,
    useGridApiRef,
} from "@mui/x-data-grid-pro";
import { isFunction } from "lodash";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { isStringType, isType } from "utils/TypeGuards";
import AcxMenu, { AcxMenuItemProps, StyledMenuLabel } from "../Menu/AcxMenu";
import AcxDataGridStore, { CustomControlItem } from "./AcxDataGridStore";
import AcxGridSelection from "./AcxGridSelection";
import FilterIcon from "./FilterOverride/FilterIcon";
import HideColumn from "./HideColumnOverride/HideColumn";
import VertIcon from "./VertIcon";
import theme from "Theme/AppTheme";
import AcxDataGridPagination from "./GridComponents/GridPagination";

export interface IAcxDataGrid {
    dataGridStore: AcxDataGridStore;
}

const AcxDataGrid = observer((props: IAcxDataGrid) => {
    const navigate = useNavigate();

    const gridApi = useGridApiRef();

    const store = props.dataGridStore;

    const filterIconRef = React.useRef<HTMLDivElement>(null);

    const handleVertClick = (event: React.MouseEvent<HTMLElement>) => {
        store.menuAnchor = event.currentTarget;
        event.stopPropagation();
        event.preventDefault();
    };

    const handleVertMenuClose = () => {
        store.menuAnchor = null;
    };

    const onClear = () => {
        store.clearSelected();
    };

    React.useEffect(() => {
        if (store?.gridApi?.current) {
            if (!store.disableLocalConfig) {
                store.gridApi.current.subscribeEvent("columnResizeStop", () => {
                    store.onColResizeOrReorder(
                        store.gridApi?.current.getAllColumns() ?? [],
                    );
                });
                store.gridApi.current.subscribeEvent(
                    "columnOrderChange",
                    () => {
                        store.onColResizeOrReorder(
                            store.gridApi?.current.getAllColumns() ?? [],
                        );
                    },
                );
            }

            store.gridApi.current.subscribeEvent(
                "rowCountChange",
                (count: number) => {
                    props.dataGridStore?.onRowCountChange?.(count);
                },
            );
        }
    }, [store.gridApi, store, props.dataGridStore]);

    function isTypeElementAndSize(
        arg: React.ReactElement | CustomControlItem,
    ): arg is CustomControlItem {
        return isType<CustomControlItem>(arg, "controlElement");
    }

    const getHeader = () => {
        if (store.title) {
            return <Typography variant="subtitle2">{store.title}</Typography>;
        }

        if (isStringType(store.rowTerm)) {
            const header = `${store.rows?.length} ${store.rowTerm ?? "Items"} `;
            return (
                <Typography variant="subtitle1">
                    {header}
                    {store.selectedRowIds &&
                        store.selectedRowIds.length > 0 &&
                        !store.selectionContextOptions && (
                            <>
                                ({store.selectedRowIds.length} selected
                                <Tooltip title="Clear all selections">
                                    <IconButton
                                        size="small"
                                        color="secondary"
                                        onClick={onClear}
                                    >
                                        <ClearIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                                )
                            </>
                        )}
                </Typography>
            );
        } else {
            return store.rowTerm;
        }
    };

    const headerColumnSpan = store.headerColumnSpan
        ? store.headerColumnSpan
        : 3;

    const controlsColumnSpan = store.controlsColumnSpan
        ? store.controlsColumnSpan
        : 9;

    useEffect(() => {
        const hideColumnMenuItem = {
            props: {
                onClick: () => {
                    store.closeMenu();
                    store.showHideColumns();
                },
            },
            icon: <ViewColumn />,
            id: `${store.gridId}-hide-col-menu-item`,
            label: <StyledMenuLabel>Manage Columns</StyledMenuLabel>,
        } as AcxMenuItemProps;
        store.vertIconMenuItems = isFunction(store.vertIconMenuItemsBuilder)
            ? store.vertIconMenuItemsBuilder(store.closeMenu, navigate)
            : store.vertIconMenuItemsBuilder ?? [];

        store.vertIconMenuItems.push(hideColumnMenuItem);
    }, [navigate, store, store.vertIconMenuItemsBuilder]);

    useEffect(() => {
        if (gridApi?.current) {
            props.dataGridStore.gridApi = gridApi;
        }
    }, [gridApi, props.dataGridStore]);

    // This ensures, if from outside sources we have selected rows (while using Ava)
    // we select those rows when the gridApi is finally available
    const gridApiRefKeyCount = Object.keys(gridApi.current ?? {}).length
    useEffect(() => {
        if (Object.keys(gridApi.current ?? {}).length > 0) {
            gridApi.current.setRowSelectionModel(store.selectedRowIds)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridApiRefKeyCount])

    return (
        <>
            <Grid
                container
                alignItems="flex-end"
                sx={{
                    position: "relative",
                    marginRight: "-5px",
                    marginTop: theme.spacing(1.5),
                    marginBottom: theme.spacing(0.5),
                }}
            >
                {Boolean(store.selectionContextOptions) && (
                    <Grid item xs={12} style={{ marginBottom: "16px" }}>
                        <AcxGridSelection store={store} />
                    </Grid>
                )}

                <Grid
                    container
                    item
                    xs={headerColumnSpan}
                    justifyContent={store.headersJustifyProperty}
                >
                    {getHeader()}
                </Grid>

                <Grid item xs={controlsColumnSpan}>
                    <Grid
                        container
                        justifyContent={
                            store.controlsJustifyProperty ?? "flex-end"
                        }
                        alignItems={store.controlsAlignProperty ?? "flex-end"}
                        alignContent="flex-end"
                        style={{
                            ...store.controlsColumnStyle,
                            marginLeft: "0px",
                        }}
                    >
                        {store.controls?.map((arg, index) => {
                            let control = arg;

                            if (isTypeElementAndSize(arg)) {
                                control = arg.controlElement;
                            }

                            const controlMargin = store.controlMargin ?? {
                                marginRight: "0.5rem",
                                marginBottom: "0.5rem",
                            };

                            return (
                                <span
                                    key={
                                        (control as React.ReactElement).key ??
                                        "control-" + index
                                    }
                                    style={{
                                        ...controlMargin,
                                        ...(arg as CustomControlItem).style,
                                    }}
                                >
                                    {control as React.ReactNode}
                                </span>
                            );
                        })}
                        {!store.hideFilter && (
                            <FilterIcon
                                store={store}
                                // @ts-ignore
                                onClick={(e) => {
                                    store.gridApi?.current?.showFilterPanel();
                                }}
                                vertIconEnabled={!!store.vertIconMenuItems}
                                ref={filterIconRef}
                                gridApiRef={store.gridApi}
                                isFiltered={
                                    !!store.gridApi?.current?.state?.filter
                                        ?.filterModel?.items.length &&
                                    store.gridApi?.current?.state?.filter?.filterModel?.items.every(
                                        (item) => !!item.value,
                                    )
                                }
                            />
                        )}
                        {store.vertIconMenuItems &&
                            Array.isArray(store.vertIconMenuItems) &&
                            store.vertIconMenuItems.length > 0 &&
                            !store.hideVertIcon && (
                                <Grid item>
                                    <VertIcon onClick={handleVertClick} />

                                    <AcxMenu
                                        menuItems={
                                            isFunction(store.vertIconMenuItems)
                                                ? store.vertIconMenuItems(
                                                      store.closeMenu,
                                                      navigate,
                                                  )
                                                : store.vertIconMenuItems
                                        }
                                        anchorOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "left",
                                        }}
                                        anchorElement={store.menuAnchor}
                                        onMenuClose={handleVertMenuClose}
                                    />
                                </Grid>
                            )}
                    </Grid>
                </Grid>
            </Grid>

            {store.preHeader && <Grid item>{store.preHeader}</Grid>}

            <div
                style={{
                    width: "100%",
                    height: `calc(100% - ${store.removeHeight ?? "0px"})`,
                    marginTop: "0.25rem",
                    boxShadow:
                        "0 0 3px 0 rgba(0, 0, 0, 0.05), 0 1px 1px 0 rgba(0, 0, 0, 0.05)",
                    overscrollBehaviorX: "contain",
                    overscrollBehaviorY: "auto",
                    backgroundColor: theme.palette.white.main,
                }}
            >
                <DataGridPro
                    columnVisibilityModel={store.visibilityModel}
                    columns={toJS(store.columns)}
                    rows={toJS(store.rows)}
                    checkboxSelection={store.checkboxSelection}
                    loading={store.isLoading}
                    apiRef={store.gridApi}
                    isRowSelectable={store.isRowSelectable}
                    slots={{
                        filterPanel: GridFilterPanel,
                        pagination: (props) => (
                            <AcxDataGridPagination {...props} store={store} />
                        ),
                    }}
                    slotProps={{
                        filterPanel: {
                            filterFormProps: {
                                logicOperatorInputProps: {
                                    variant: "outlined",
                                    size: "small",
                                    sx: {
                                        mr: theme.spacing(0.5),
                                        mt: theme.spacing(0.5),
                                    },
                                },
                                columnInputProps: {
                                    variant: "outlined",
                                    size: "small",
                                    sx: {
                                        mr: theme.spacing(0.5),
                                        mt: theme.spacing(0.5),
                                    },
                                    label: "Select Field",
                                },
                                operatorInputProps: {
                                    variant: "outlined",
                                    size: "small",
                                    sx: {
                                        mr: theme.spacing(0.5),
                                        mt: theme.spacing(0.5),
                                    },
                                    // TODO: figure out how to change this label... why is it different from valueInputProps?
                                    // InputComponentProps: {
                                    //     label: "Select Operator",
                                    // },
                                    // label: "Select Operator",
                                    // placeholder: "test",
                                    // inputLabel: "test",
                                    // formLabel: "test",
                                    // legend: "test",
                                },
                                valueInputProps: {
                                    InputComponentProps: {
                                        variant: "outlined",
                                        size: "small",
                                        sx: {
                                            mr: theme.spacing(0.5),
                                            mt: theme.spacing(0.5),
                                        },
                                        label: "Search Value",
                                    },
                                },
                            },
                        },
                    }}
                    disableColumnMenu
                    pagination={store.pagination}
                    pageSizeOptions={store.pageSizeOptions}
                    rowCount={store.rowCount ?? store.rows.length}
                    paginationModel={
                        store.paginationModel ?? { pageSize: 10, page: 0 }
                    }
                    onPaginationModelChange={store.onPaginationModelChange}
                    paginationMode={store.paginationMode}
                    rowHeight={42}
                    onRowSelectionModelChange={(
                        rowSelectionModel: GridRowSelectionModel,
                    ) => {
                        props.dataGridStore.handleSelectionChange(
                            rowSelectionModel as GridRowId[],
                        );
                    }}
                    filterModel={store.filterModel}
                    onFilterModelChange={(input: GridFilterModel) => {
                        if (!props.dataGridStore) return;
                        props.dataGridStore.filterModel = input;
                    }}
                />
            </div>
            {gridApi?.current && <HideColumn store={store} />}
        </>
    );
});

export default AcxDataGrid;
