import { useStore } from "utils/useStore";
import AgentStore from "./Stores/AgentStore";
import AgentChat from "./AgentChat";
import { Drawer, keyframes } from "@mui/material";
import React from "react";
import { observer } from "mobx-react";

const fade = keyframes`
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
`;

export const AgentDrawer = observer(
    ({
        componentsProps,
        PaperProps,
        ...props
    }: React.ComponentPropsWithoutRef<typeof Drawer>) => {
        const agentStore = useStore(AgentStore);

        return (
            <Drawer
                anchor="right"
                transitionDuration={0}
                hideBackdrop
                open={agentStore.isDrawerOpen}
                onClose={() => agentStore.closeDrawer()}
                {...props}
                componentsProps={{
                    ...componentsProps,
                    root: {
                        ...componentsProps?.root,
                        style: {
                            zIndex: 1099,
                            maxWidth: "fit-content",
                            ...componentsProps?.root?.["style"],
                        },
                    },
                }}
                PaperProps={{
                    ...PaperProps,
                    sx: {
                        zIndex: 1099,
                        marginTop: "72px",
                        animation: `${fade} 300ms forwards`,
                        ...PaperProps?.["sx"],
                    },
                }}
            >
                <AgentChat close={() => agentStore.closeDrawer()} />
            </Drawer>
        );
    },
);
