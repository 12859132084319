import { observer } from "mobx-react";
import React from "react";
import { FilterKey } from "stores/ApplicationFilters/ApplicationFilterItemsStore";
import {
    AdverseEventChip,
    CallDirectionsChips,
    CallDurationChip,
    ClassifierChips,
    DefaultAppliedFilterChip,
    EddyEffectChip,
    EvaluationTypesChips,
    FavoriteConversationsChip,
    HierarchiesChips,
    HipaaComplianceChip,
    MediaTypesChips,
    SafetyEventChip,
    SentimentChip,
    TopicsChips
} from ".";
import { AgentChips } from "../Agent/AgentChips";
import { ContactTypesChips } from "../ContactTypes/ContactTypesChips";
import { IAppliedFilterChipComponent } from "./IAppliedFilterChipComponent";

export const filterKeyToChip: Record<
    FilterKey,
    React.FunctionComponent<IAppliedFilterChipComponent> | undefined
> = {
    callDuration: CallDurationChip,
    minCallDuration: undefined,
    maxCallDuration: undefined,
    startDate: undefined,
    endDate: undefined,
    dateRange: undefined,
    dateReferenceOption: undefined,
    dateRangeLabel: undefined,
    evaluationTypes: EvaluationTypesChips,
    hierarchyIds: HierarchiesChips,
    selectedCategories: undefined,
    selectedSubcategories: undefined,
    agentIds: AgentChips,
    mediaTypes: MediaTypesChips,
    callDirections: CallDirectionsChips,
    clientCallId: DefaultAppliedFilterChip,
    meta1: DefaultAppliedFilterChip,
    meta2: DefaultAppliedFilterChip,
    meta3: DefaultAppliedFilterChip,
    meta4: DefaultAppliedFilterChip,
    meta5: DefaultAppliedFilterChip,
    extendedMetadata: DefaultAppliedFilterChip,
    beginningSentiment: SentimentChip,
    endingSentiment: SentimentChip,
    eddyEffectStatus: EddyEffectChip,
    safetyEvent: SafetyEventChip,
    hipaaCompliance: HipaaComplianceChip,
    adverseEvent: AdverseEventChip,
    topics: TopicsChips,
    containsTopics: undefined,
    wordsAndPhrasesSearchString: DefaultAppliedFilterChip,
    wordsAndPhrasesItems: undefined,
    wordsAndPhrasesIsInputStandard: undefined,
    rbcFilterItems: ClassifierChips,
    contactTypes: ContactTypesChips,
    favoriteConversations: FavoriteConversationsChip
};

export const FilterChip = observer(function (
    props: IAppliedFilterChipComponent,
) {
    let FilterChipComponent = filterKeyToChip[props.item.filterKey];

    const isExtendedeMetaItem =
        props.item.filterKey.startsWith("extendedMetadata");
    if (isExtendedeMetaItem)
        FilterChipComponent = filterKeyToChip["extendedMetadata"];

    if (!FilterChipComponent) {
        console.error(
            `Invalid or unsupported filter key: ${props.item.filterKey}. Nothing rendered.`,
        );
        return null;
    }

    return <FilterChipComponent {...props} />;
});
