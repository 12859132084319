import { OrganizationAgentStore } from "components/Admin/Organizations/OrganizationDetail/OrganizationAgents/stores/OrganizationAgentStore";
import { PartialSegment } from "components/SoundClipEditor/SoundClipEditor";
import SoundClipEditorStore from "components/SoundClipEditor/Stores/SoundClipEditorStore";
import { SpeakerStore } from "components/UI/AcxTranscription/Stores/SpeakerStore/SpeakerStore";
import * as _ from "lodash";
import {
    action,
    computed,
    makeObservable,
    observable,
    reaction,
    runInAction,
    toJS,
} from "mobx";
import { computedFn } from "mobx-utils";
import { Answer } from "models/Answer";
import { AnswerTag } from "models/AnswerTag";
import { EddyEffectP2Results } from "models/EddyEffectP2Results";
import type { HighlightsForTagResults } from "models/HighlightsForTagResults";
import LicensedModule, { LMType, RenderPlacement } from "models/LicensedModule";
import { ApplicationUser } from "models/Permission/ApplicationUser";
import { SoundClipAnswer } from "models/SoundClipAnswer";
import { AssignedWorkflowInstance, WorkflowStatus } from "models/Workflows/WorkflowInstance";
import moment, { Moment } from "moment";
import { User } from "oidc-client";
import React from "react";
import { ServiceError } from "services/BaseService";
import { HierarchyService } from "services/HierarchyService";
import { MetadataService } from "services/MetadataService";
import { ModuleService } from "services/ModuleService";
import type {
    IMultiLanguageTranscriptionPayload,
    ITranscriptionPayload,
    ITranscriptionSegment,
} from "services/TranscriptionService";
import { TranscriptionService } from "services/TranscriptionService";
import { LayoutDrawerStore } from "stores/Layout/LayoutDrawerStore";
import type { IRootStore } from "stores/RootStore";
import { AcxStore } from "stores/RootStore";
import { Speaker, getSpeakerColorByChannelOrPersona } from "Theme/utils";
import {
    computeAverageUtteranceForTranscript,
    getTranscriptByLangCode,
} from "utils/Utils";
import { v4 as uuid } from "uuid";
import { Action } from "../../../models/Actions/Action";
import { EvalReviewActionMetadata } from "../../../models/Actions/Evaluation/EvalReviewActionMetadata";
import Agentv2 from "../../../models/Agentv2";
import { ClassifierResult } from "../../../models/ClassifierResult";
import { ClassifierResultValidation } from "../../../models/ClassifierResultValidation";
import CustomerType from "../../../models/CustomerType";
import { DataProcessingOptions } from "../../../models/DataProcesses";
import Evaluation, {
    EvalType,
    EvaluationStatus,
} from "../../../models/Evaluation";
import EvaluationModule from "../../../models/EvaluationModule";
import { InteractionHierarchyLevels } from "../../../models/InteractionHierarchyLevels";
import { InteractionType } from "../../../models/InteractionType";
import Question, { QuestionType } from "../../../models/Question";
import type { MediaClip } from "../../../models/SoundClip";
import SoundClip, { ClipUIModel } from "../../../models/SoundClip";
import { StorageAccountUseOptions } from "../../../models/StorageAccount";
import { Tag } from "../../../models/Tag";
import { ITranscriptionEntity } from "../../../models/TranscriptionEntity";
import { EvaluationActionService } from "../../../services/Actions/EvaluationActionService";
import AgentService from "../../../services/AgentService";
import { AudioFilesService } from "../../../services/AudioFilesService";
import { ChatMediaService } from "../../../services/ChatMediaService";
import ClassifierService from "../../../services/ClassifierService";
import CustomerTypesService from "../../../services/CustomerTypesService";
import { EvaluationDisputeService } from "../../../services/EvaluationDisputeService";
import type { IEvalPermissionsModel } from "../../../services/EvaluationService";
import {
    EvaluationAnswerHistory,
    EvaluationRequestModel,
    EvaluationService,
    EvaluationUpdateResponse,
} from "../../../services/EvaluationService";
import { OrganizationService } from "../../../services/OrganizationService";
import type { UpdateSoundClipRequest } from "../../../services/SoundClipService";
import {
    SoundClipMedia,
    SoundClipService,
} from "../../../services/SoundClipService";
import { ActionRecipientStore } from "../../../stores/ActionRecipientStore";
import { AuthStore } from "../../../stores/AuthStore";
import { AsyncTaskStatus, BaseStore } from "../../../stores/BaseStore";
import { DialogComponentStore } from "../../../stores/ComponentStores/DialogComponentStore";
import LocalStorage from "../../../stores/LocalStorage";
import { serializeToUtc } from "../../../utils/DateTimeUtils";
import { delay } from "../../../utils/helpers";
import { parseStringTags } from "../../../utils/soundClipStringTagHelper";
import { isNullableType } from "../../../utils/TypeGuards";
import MessageStore from "../../ManagerInteractions/Stores/MessageStore";
import { ChatPersona, IChatRecord } from "../../UI/Chat/AcxChatViewer";
import { ModuleUIModel } from "../Models/ModuleUIModel";
import {
    generateClipForEvaluation,
    groupedTagsFromTags,
    matchTranscriptionToSoundCLip,
    prepareEvalForSerialization,
    soundClipDurationDisplay,
    textClipTimestampDisplay,
} from "../Utils";
import AnswerHistoryView from "../Views/AnswerHistoryView";
import { isTextMediaType } from "../Views/EvalPage";
import { shouldRender } from "../Views/Modules/QuestionAnswerList";
import { EvalWorkflowStore } from "./EvalWorkflowStore";
import { EvalWorkRestorer } from "./EvalWorkRestorer";

export interface ModuleName {
    typeName: string;
    displayName: string;
    hasDisputedAnswers: boolean;
}

export type ClipTag = Tag;

export interface EvalLicensedModule extends LicensedModule {
    evaluationModuleId: string;
    evaluationModule: EvaluationModule;
    hasDisputedAnswers: boolean;
}

export interface EvalQuestion extends Question {
    evaluationModuleId: string;
}

export interface TranscriptionSearchResult {
    phraseStartTime: number;
    stmt: string;
    startTime: number;
    endTime: number;
}

export interface GroupedTags {
    label: string;
    options: ClipTag[];
}

export interface AISpeakerHighlight {
    speaker: string;
    highlightText: string;
    wordsForRbc?: WordForRbc[];
    color?: string;
}

export type StructuredHighlights = {
    aiSpeakerHighlights: AISpeakerHighlight[];
} & HighlightsForTagResults;

type WordForRbc = {
    phrase: string;
    count: number;
};

export const UpdateEvaluationTask = "Updating Evaluation";
export const LoadEvaluationDependenciesTask = "Loading Evaluation Dependencies";
export const LoadEvaluationTask = "Loading Evaluation";
export const LoadModulesTask = "Load Licensed Modules";
export const LoadAudioTranscriptionTask = "Loading Audio Transcription";
export const LoadEddyEffectP2Results = "Loading EddyEffectP2 Results";
export const LoadAnswerHistory = "Loading Answer History";
export const createAudioClipTask = "Creating Audio Clip";
export const UpdateAudioClip = "Updating Audio Clip";
export const GenerateDispute = "Generating dispute request";
export const ClassifierValidationModuleTypeName = "ClassifierValidation";
export const CapaReviewModulesTypeName = "APT Review";
export const DisputeReviewModulesTypeName = "Dispute Review";
export const ConvertToHumanEval = "Convert to Human Eval";

type Status = "Ok" | "Failed";

@AcxStore
export class EvalStore extends BaseStore {
    // static createNewAgent = new Agentv2();

    readonly authStore: AuthStore;
    readonly agentStore: OrganizationAgentStore;
    readonly drawerStore: LayoutDrawerStore;

    private readonly evalActionService: EvaluationActionService =
        new EvaluationActionService();
    private readonly chatMediaService: ChatMediaService =
        new ChatMediaService();
    private readonly audioFilesService: AudioFilesService =
        new AudioFilesService();
    private readonly organizationService: OrganizationService =
        new OrganizationService();
    private readonly customerTypesService: CustomerTypesService =
        new CustomerTypesService();
    private readonly transcriptionService: TranscriptionService =
        new TranscriptionService();
    private readonly evaluationService: EvaluationService =
        new EvaluationService();
    private readonly evaluationDisputeService: EvaluationDisputeService =
        new EvaluationDisputeService();
    private readonly agentService: AgentService = new AgentService();
    private readonly moduleService: ModuleService = new ModuleService();
    private readonly soundClipService: SoundClipService =
        new SoundClipService();
    private readonly classifierService: ClassifierService =
        new ClassifierService();
    private readonly metadataService: MetadataService = new MetadataService();
    private readonly hierarchyService: HierarchyService =
        new HierarchyService();

    readonly requestActionDialogStore: DialogComponentStore<EvalStore> =
        new DialogComponentStore<EvalStore>(undefined, this);
    readonly saveEvalDialogStore: DialogComponentStore<EvalStore> =
        new DialogComponentStore<EvalStore>(undefined, this);
    readonly noActionsFoundDialogStore: DialogComponentStore<EvalStore> =
        new DialogComponentStore<EvalStore>(undefined, this);
    readonly createAgentDialogStore: DialogComponentStore<EvalStore> =
        new DialogComponentStore<EvalStore>(undefined, this);
    readonly requestDisputeDialogStore: DialogComponentStore<EvalStore> =
        new DialogComponentStore<EvalStore>(undefined, this);
    readonly reassignHierarchyDialogStore: DialogComponentStore<EvalStore> =
        new DialogComponentStore<EvalStore>(undefined, this);

    // TODO
    // add new constant string for every dynamic module
    readonly dynamicEvalModules = [
        ClassifierValidationModuleTypeName,
        CapaReviewModulesTypeName,
    ];

    // NOTE - these variables must be cleared when switching Evaluation Ids;
    @observable currentDynamicModule?: string;

    @observable showThread: boolean = false;
    @observable showThreadHistory: boolean = false;
    @observable showAnswerHistory: boolean = false;
    @observable currentEvalId?: string;
    @observable currentModule?: LicensedModule;
    @observable bossModule?: LicensedModule;
    @observable currentEval?: Evaluation;
    @observable currentMediaUrl?: string;
    @observable currentSoundClipMedia?: SoundClipMedia[];
    @observable evalAudio?: ClipUIModel;
    @observable evalChat?: IChatRecord[];
    @observable evalAnswerHistory?: EvaluationAnswerHistory[];
    @observable.ref
    evalAudioTranscription?: ITranscriptionSegment[];
    @observable.ref
    evalMultiLangTranscriptions?: IMultiLanguageTranscriptionPayload[];
    @observable
    selectedTranscriptLanguage: IMultiLanguageTranscriptionPayload["languageCode"] =
        "en";
    @observable mediaDrawerOpen: boolean = false;
    @observable transcriptionData?: ITranscriptionPayload;
    @observable eddyEffectP2Data: EddyEffectP2Results[];
    @observable audioEditorStatus?: Status = undefined;
    @observable mediaDrawerWidth: number = 0;
    @observable mediaDrawerHeight: number = 0;
    @observable evalAudioBuffer?: AudioBuffer;
    @observable evalAudioLoading: boolean = false;
    @observable.shallow undoTracker: string[] = [];
    @observable.shallow interactionHierarchyLevels:
        | InteractionHierarchyLevels[]
        | undefined;
    @observable.shallow licensedModulesForEval?: EvalLicensedModule[];
    @observable private moduleUIModels: Map<string, ModuleUIModel> =
        observable.map();

    @observable.shallow clipTags: Array<ClipTag> = [];
    @observable groupClipTags: GroupedTags[] = [];

    @observable.ref redactedClipDownloader?: () => Promise<ArrayBuffer>;
    @observable.ref activeClip: SoundClip | undefined;
    @observable.ref activeClipInverse: SoundClip | undefined;
    @observable openClipId: string | null = null;
    @observable.ref transcriptionModels?: ITranscriptionEntity[];
    @observable stageDeleteClip: SoundClip | null = null;
    private averageUtteranceDuration: number | null = null;

    // CLASSIFICATION VALIDATION
    @observable classifierValidationEnabled: boolean = false;
    @observable.ref positiveClassifiers?: ClassifierResult[];
    @observable.ref allClassifiers: ClassifierResult[] | undefined;

    // NOTE -- these variables can persist switching Evaluation Id's but not Organizations
    @observable organizationId?: string;
    @observable.ref customerTypes?: CustomerType[];
    @observable.ref agentList: Agentv2[] = [];
    @observable.ref private tags?: Array<Tag>;
    @observable.shallow private licensedModules: LicensedModule[] = [];

    // NOTE -- these variables never change
    @observable user: User | null = null;

    private readonly workRestorer: EvalWorkRestorer;
    private readonly undoLocalStorage: LocalForage;

    readonly workflowStore: EvalWorkflowStore;

    readonly speakerStore: SpeakerStore;

    lastEvalRecord?: Evaluation;
    @observable loadLastEval: boolean = false;

    @observable disputeHasStarted: boolean = false;

    @observable showInteractionMetadata: boolean = false;

    @observable searchStatus: boolean = false;
    @observable searchString: any;

    @observable selectedReassignHierarchyId: string;

    @observable extendedMetadataValues?: any;

    @observable evaluationPermissions?: IEvalPermissionsModel;

    @observable showNotRedactedWarning: boolean = false;

    @observable convertToHumanModalOpen: boolean = false;

    @observable analystOptions: ApplicationUser[] = [];

    @observable structuredHighlights: Map<string, StructuredHighlights> =
        new Map();

    @observable explanationLoadingStates: { [key: string]: boolean } = {};

    @observable showModifiedWarning: boolean = false;

    @observable showAIExplanation: { [key: string]: boolean } = {};

    @observable soundClipEditorStore: SoundClipEditorStore;

    @observable enableAutoBindClips: boolean = true;

    @observable acknowledgeWindowHasClosed: boolean = false;

    @observable hasInProgressWorkflows: boolean = false;

    constructor(public rootStore: IRootStore) {
        super("EvalStore");
        makeObservable(this);

        this.authStore = rootStore.getStore(AuthStore);
        this.agentStore = rootStore.getStore(OrganizationAgentStore);
        this.workflowStore = new EvalWorkflowStore(this);
        this.workRestorer = new EvalWorkRestorer(rootStore);
        this.drawerStore = this.rootStore.getStore(LayoutDrawerStore);
        this.speakerStore = new SpeakerStore();

        this.loadUserProfile();

        // EvalStore.createNewAgent.fullName = "Create New Agent";
        // EvalStore.createNewAgent.id = "createNewAgent";

        this.undoLocalStorage = rootStore
            .getStore(LocalStorage)
            .getLocalStore("EvalUndo");

        reaction(
            (r) => ({
                hierarchyId:
                    this.currentEval?.interaction
                        ?.organizationStructureMemberId,
                userId: this.user?.profile.sub,
                orgId: this.organizationId,
                activeLocation: this.rootStore.activeLocation,
            }),
            (arg) => {
                if (
                    arg.activeLocation &&
                    !arg.activeLocation.location.includes("evaluation")
                ) {
                    return;
                }

                if (!arg.orgId || !arg.hierarchyId || !arg.userId) {
                    return;
                }

                const orgId = arg.orgId;
                const hierarchyId = arg.hierarchyId;
                const userId = arg.userId;

                const actionRecipientStore =
                    this.rootStore.getStore(ActionRecipientStore);

                actionRecipientStore.loadEvaluationRecipients(
                    orgId,
                    hierarchyId,
                    userId,
                );
            },
        );

        reaction(
            (r) => this.evalAudioTranscription,
            (txModel) => {
                if (txModel && this.currentEval?.soundClips) {
                    this.currentEval.soundClips.forEach((value) => {
                        if (!value.transcriptionText) {
                            this.transcriptionForClip(value);
                        }
                    });
                }
            },
            { delay: 16 },
        );

        reaction(
            (r) => ({ evalAudio: this.evalAudio }),
            async (arg) => {
                if (
                    arg.evalAudio &&
                    this.currentEval?.interaction?.audioMetadataId &&
                    (this.transcriptionModels?.length ?? 0) > 0
                ) {
                    const amdId = this.currentEval.interaction.audioMetadataId;
                    this.setupAsyncTask(LoadAudioTranscriptionTask, () =>
                        this.loadTranscriptionForAudioInteraction(amdId),
                    );
                }
            },
            { delay: 16 },
        );

        reaction(
            (r) => ({
                evaluation: this.currentEval,
            }),
            async (arg) => {
                if (arg.evaluation?.interaction?.agentId) {
                    const agt = await this.agentService.getAgent(
                        arg.evaluation?.interaction?.agentId,
                    );
                    this.agentList = [agt];
                }
                this.showAIExplanation = {};
                this.structuredHighlights = new Map();
            },
        );

        reaction(
            (r) => ({
                evaluation: this.currentEval,
                modules: [...this.licensedModules],
            }),
            (arg) => {
                if (
                    arg.evaluation &&
                    arg.evaluation.evaluationModules &&
                    arg.modules &&
                    arg.modules.length > 0
                ) {

                    this.hasInProgressWorkflows = (arg.evaluation.workflowInstances as AssignedWorkflowInstance[] || []).some(
                        (workflow) => workflow.status !== WorkflowStatus.Closed
                      );

                      const licensedModulesForEval = [] as EvalLicensedModule[];
                      for (let module of arg.modules as EvalLicensedModule[]) {
                          if (module.lmType === LMType.Workflow) {
                              let evalModules =
                                  arg.evaluation.evaluationModules.filter(
                                      (value) =>
                                          value.licensedModuleId === module.id,
                                  );
  
                              for (let evalModule of evalModules) {
                                  if (evalModule) {
                                      const workflowModule =
                                          LicensedModule.fromJson(
                                              _.cloneDeep(
                                                  toJS(module),
                                              ) as EvalLicensedModule,
                                          ) as EvalLicensedModule;
                                      workflowModule.evaluationModule =
                                          evalModule;
                                      workflowModule.evaluationModuleId =
                                          evalModule.id;
                                      workflowModule.id =
                                          evalModule.licensedModuleId;
                                      licensedModulesForEval.push(workflowModule);
                                  }
                              }
                          } else {
                              let evalModule =
                                  arg.evaluation.evaluationModules.find(
                                      (value) =>
                                          value.licensedModuleId === module.id,
                                  );
  
                              if (evalModule) {
                                  module.id = evalModule.licensedModuleId;
                                  module.evaluationModuleId = evalModule.id;
                                  module.evaluationModule = evalModule;
                                  licensedModulesForEval.push(module);
                              }
                          }
                      }

                    runInAction(() => {
                        this.licensedModulesForEval = licensedModulesForEval
                            .filter((lm) => lm.isActive)
                            .sort((a, b) => a.order - b.order);

                        const tags = [] as Tag[];
                        this.licensedModulesForEval.forEach((lm) => {
                            lm.questions.forEach((ques) => {
                                tags.push(
                                    ...ques.tags.filter(
                                        (value) => value.isActive,
                                    ),
                                );
                            });
                        });

                        this.setTags(tags.map((value) => Tag.fromJson(value)));

                        for (let licensedModule of this
                            .licensedModulesForEval) {
                            if (
                                !this.moduleUIModels.has(
                                    licensedModule.evaluationModuleId,
                                )
                            ) {
                                this.moduleUIModels.set(
                                    licensedModule.evaluationModuleId,
                                    new ModuleUIModel(
                                        licensedModule.questions,
                                        licensedModule,
                                        this.clipTags,
                                        this.currentEval,
                                    ),
                                );
                            }
                        }

                        this.groupClipTags.splice(
                            0,
                            this.groupClipTags.length,
                            ...groupedTagsFromTags(this.clipTags),
                        );

                        arg.evaluation?.answers
                            .flatMap((value) => value.answerTags)
                            .forEach((value) => {
                                value.tag = this.tags?.find(
                                    (value1) => value1.id === value.tagId,
                                );
                            });
                        arg.evaluation?.answers
                            .flatMap((value) => value.soundClipAnswers)
                            .forEach((value) => {
                                value.soundClip =
                                    arg.evaluation?.soundClips?.find(
                                        (value1) =>
                                            value1.id === value.soundClipId,
                                    );
                            });

                        arg.evaluation?.soundClips?.forEach((value) => {
                            parseStringTags(this.clipTags, value);
                        });

                        let initCurrentModule: LicensedModule | undefined;

                        initCurrentModule = toJS(
                            this.licensedModulesForEval?.filter(
                                (v) =>
                                    v.renderPlacement ===
                                        RenderPlacement.Middle &&
                                    !v.isWorkflowModule,
                            ),
                        )?.[0];

                        if (initCurrentModule && !this.currentModule) {
                            this.setCurrentModule(initCurrentModule);
                        }
                    });
                }
            },
            { delay: 5 },
        );

        reaction(
            () => ({
                serialized: JSON.stringify(toJS(this.currentEval)),
                evaluation: this.currentEval,
            }),
            this.debounceEffect((arg) => {
                if (arg.evaluation) {
                    this.handleChange(arg);
                }
            }, 750),
        );

        reaction(
            () => ({
                userId: this.user?.profile.sub,
                orgId: this.authStore.orgStore.selectedOrganization?.id,
            }),
            () => {
                this.getAnalystOptions();
            },
        );

        window.addEventListener("unload", this.clearLocalStore);
    }

    private loadUserProfile(): Promise<User | null> {
        return this.authStore
            .getUserObject()
            .then((value) => {
                runInAction(() => {
                    this.user = value;
                });
                return value;
            })
            .catch((reason) => {
                console.error(`EvalStore::failed to load userInfo ${reason}`);
                return null;
            });
    }

    // @action
    // setLastEvalRecord = (evalRecord: Evaluation) => {
    //     this.lastEvalRecord = evalRecord;
    // };

    @action
    toggleShowInteractionMetadata = async () => {
        this.showInteractionMetadata = !this.showInteractionMetadata;
        if (this.showInteractionMetadata) {
            await this.setupAsyncTask(`Get Extended Metadata`, async () => {
                if (
                    this.currentEval?.interaction?.audioMetadataId &&
                    !this.extendedMetadataValues
                ) {
                    const extendedMetadata =
                        await this.metadataService.getExtendedMetadataValuesById(
                            this.currentEval?.interaction?.audioMetadataId,
                        );
                    if (Object.keys(extendedMetadata).length > 0) {
                        this.extendedMetadataValues = extendedMetadata;
                    }
                }
            });
        }
    };

    // AGENTS
    @action
    openCreateAgent = () => {
        this.agentStore.setSelectedAgentHierarchies([]);
        this.createAgentDialogStore.open();
    };

    @action
    onAgentCreateConfirm = (
        firstName: string,
        lastName: string,
        agentEmail: string,
        agentCode: string,
        hierarchies: string[],
        startDate?: Moment,
        managerId?: string,
    ) => {
        this.setupAsyncTask(`Create Agent`, async () => {
            const newAgent = await this.agentService.createAgent(
                firstName,
                lastName,
                agentEmail,
                agentCode,
                this.organizationId,
                hierarchies,
                startDate,
                managerId,
            );
            this.createAgentDialogStore.close();
            runInAction(() => {
                this.agentList = [newAgent];
                this.currentEval?.interaction?.setAgent(newAgent);
            });
        });
        // .then(() => {
        //     if (this.organizationId) {
        //         return this.getAgents(this.organizationId);
        //     }
        // })
    };

    @action
    private async loadTranscriptionForAudioInteraction(amdId: string) {
        this.setupAsyncTask("Load transcription", async () => {
            const mulitLangTranscriptData =
                await this.transcriptionService.getMulitLanguageTranscriptionByAmdId(
                    amdId,
                );
            if (!_.isEmpty(mulitLangTranscriptData)) {
                runInAction(() => {
                    this.evalMultiLangTranscriptions = mulitLangTranscriptData;
                    let transcriptionData = getTranscriptByLangCode(
                        "en",
                        mulitLangTranscriptData,
                    );
                    this.selectedTranscriptLanguage = "en";

                    this.evalAudioTranscription = transcriptionData ?? [];
                    this.transcriptionData = mulitLangTranscriptData.find(
                        (item) => item.languageCode === "en",
                    )?.transcription;

                    if ((this.evalAudioTranscription?.length ?? 0) > 0) {
                        this.averageUtteranceDuration =
                            this.evalAudioTranscription.reduce(
                                (p, c) =>
                                    p + ((c.endTime ?? 0) - (c.startTime ?? 0)),
                                0,
                            ) / this.evalAudioTranscription.length;
                    }
                });
            }
        });
    }

    @action
    async loadTEddyEffectP2Results(amdId?: string) {
        if (!amdId) return;
        this.eddyEffectP2Data = [];
        const eddyEffectP2Results =
            await this.classifierService.getEddyEffectP2Results(amdId);

        if (!_.isEmpty(eddyEffectP2Results)) {
            runInAction(() => {
                this.eddyEffectP2Data = eddyEffectP2Results.map((eddy) => ({
                    startWordIndex: eddy.eddyStartWordIndex,
                    endWordIndex: eddy.eddyEndWordIndex,
                }));
            });
        }
    }

    @action
    private prepareStoreForOrgSwitch() {
        this.customerTypes = undefined;
        this.tags = undefined;
        this.agentList = [];
        this.licensedModules = [];
    }

    @action
    public forceEvalReload(evalOnly: boolean = true) {
        if (evalOnly) {
            this.currentEvalId = undefined;
            this.prepareStoreForEvalSwitch();
        } else {
            this.currentEvalId = undefined;
            this.organizationId = undefined;
            this.prepareStoreForEvalSwitch();
            this.prepareStoreForOrgSwitch();
        }
    }

    @action
    private prepareStoreForEvalSwitch() {
        this.clearLocalStore();
        this.clearTaskErrors();
        this.showThread = false;
        this.showThreadHistory = false;
        this.classifierValidationEnabled = false;
        this.mediaDrawerHeight = 0;
        this.mediaDrawerWidth = 0;
        this.agentList = [];
        this.groupClipTags = [];
        this.currentEval = undefined;
        this.evalChat = undefined;
        this.undoTracker.splice(0, this.undoTracker.length);
        this.evalAudioLoading = false;
        this.evalAudioBuffer = undefined;
        this.audioEditorStatus = undefined;
        this.mediaDrawerOpen = true;
        this.evalAudioTranscription = undefined;
        this.evalAudio = undefined;
        this.redactedClipDownloader = undefined;
        this.moduleUIModels.clear();
        this.licensedModulesForEval = undefined;
        this.currentModule = undefined;
        this.interactionHierarchyLevels = undefined;
        this.clipTags = [];
        this.activeClip = undefined;
        this.activeClipInverse = undefined;
        this.transcriptionModels = undefined;
        this.averageUtteranceDuration = null;
        this.currentDynamicModule = undefined;
        this.positiveClassifiers = undefined;
        this.allClassifiers = undefined;
        this.workflowStore.reset();
        this.saveEvalDialogStore.resetDialog();
        this.requestActionDialogStore.resetDialog();
        this.noActionsFoundDialogStore.resetDialog();
        this.requestDisputeDialogStore.resetDialog();
        this.disputeHasStarted = false;
        this.showInteractionMetadata = false;
        this.extendedMetadataValues = undefined;
        // this.lastEvalRecord = undefined;
    }

    private async loadForOrganization(orgId: string) {
        const custTypesTask = this.getCustomerTypes(orgId);
        // const agentsTask = this.getAgents(orgId);

        await Promise.all([custTypesTask]);
    }

    private async loadFullEvalGraph(
        evalId: string | undefined,
        orgId: string | undefined,
        evaluation?: Evaluation,
    ) {
        if (evalId && orgId) {
            const modulesTask = this.getModules(orgId, evalId);

            // const restorePoint = await this.workRestorer.restore(evalId);

            // if (restorePoint) {
            //     this.restoreOrLoadEval(evalId, orgId, restorePoint);
            // } else
            if (evaluation) {
                await Promise.all([
                    modulesTask,
                    this.getEval(evalId, orgId, undefined, evaluation),
                ]);
            } else {
                await Promise.all([modulesTask, this.getEval(evalId, orgId)]);
            }

            await Promise.all([modulesTask]);
        }
    }

    // private restoreOrLoadEval(
    //     evalId: string,
    //     orgId: string,
    //     restorePoint: {
    //         evaluation: Evaluation;
    //         workflowMetadata: Record<string, WorkflowMetadata[]>;
    //     },
    // ): void {
    //     this.rootStore
    //         .getStore(MessageStore)
    //         .logMessage(
    //             "A previous Evaluation failed to save. Would you like to load your last saved state?",
    //             "info",
    //             {
    //                 buttonText: {
    //                     approve: "Restore Evaluation",
    //                     deny: "Ignore",
    //                 },
    //                 buttonAction: {
    //                     approve: () => {
    //                         this.rootStore
    //                             .getStore(MessageStore)
    //                             .showNextMessage();
    //                         this.setupAsyncTask(LoadEvaluationTask, () => {
    //                             return this.getEval(
    //                                 evalId,
    //                                 orgId,
    //                                 restorePoint,
    //                             );
    //                         });
    //                     },
    //                     deny: async () => {
    //                         this.rootStore
    //                             .getStore(MessageStore)
    //                             .showNextMessage();
    //                         this.setupAsyncTask(LoadEvaluationTask, () => {
    //                             return this.getEval(evalId, orgId);
    //                         });
    //                     },
    //                 },
    //                 timeoutOverride: 11000,
    //             },
    //         );
    // }

    @computed
    get actionRecipients() {
        return this.rootStore.getStore(ActionRecipientStore)
            .evaluationRecipients;
    }

    @action
    setActiveClipInverse(clip?: MediaClip) {
        this.activeClipInverse = clip;

        setTimeout(() => {
            runInAction(() => {
                this.activeClipInverse = undefined;
            });
        }, 1000);
    }

    @computed
    get leftRenderPlacementModuleUiModels() {
        return [...this.moduleUIModels.entries()].filter(
            (value) =>
                value[1].licensedModule.renderPlacement ===
                RenderPlacement.Left,
        );
    }

    public getClippingAddTagsOptions() {
        const visibleModules = this.licensedModulesForEval?.filter(
            (lm) =>
                lm.renderPlacement === RenderPlacement.Middle &&
                !lm.isWorkflowModule,
        );

        if (!visibleModules) return [];

        const options = Array.from({ length: visibleModules.length });
        for (let i = 0; i < visibleModules.length; i++) {
            const module = visibleModules[i];

            const questionOptions: {
                id: string;
                value?: string;
                isDisabled?: boolean;
            }[] = [];

            for (const quesiton of module.sortedQuestion) {
                if (quesiton.tags.length === 0) continue;

                questionOptions.push({
                    id: "",
                    value: quesiton.questionText,
                    isDisabled: true,
                });
                questionOptions.push(...quesiton.tags);
            }
            options[i] = {
                label: module.name,
                options: questionOptions,
            };
        }

        return options;
    }

    @computed
    get evaluationModuleNames(): ModuleName[] | undefined {
        const classifiers = this.allClassifiers?.map(
            (value) => value.classifier,
        );

        let visibleModules = this.licensedModulesForEval
            ?.filter(
                (lm) =>
                    lm.renderPlacement === RenderPlacement.Middle &&
                    !lm.isWorkflowModule,
            )
            ?.map((value) => ({
                typeName: value.name!,
                displayName: `${value.name}`,
                hasDisputedAnswers: value.hasDisputedAnswers,
            }));

        if (classifiers && classifiers.length > 0 && visibleModules) {
            visibleModules = [
                {
                    typeName: ClassifierValidationModuleTypeName,
                    displayName: "Listen For",
                    hasDisputedAnswers: false,
                },
                ...visibleModules,
            ];
        }


        return visibleModules;
    }

    @action
    setOrganizationId(orgId: string) {
        this.organizationId = orgId;
    }

    @action
    async initializeEvalStore(
        evalId: string,
        orgId: string,
        successCallback?: () => void,
        errorCallback?: (error: ServiceError) => void,
    ) {
        if (!this.user) {
            this.user = await this.authStore.getUserObject();
        }

        if (evalId && orgId) {
            this.prepareStoreForOrgSwitch();
            this.prepareStoreForEvalSwitch();

            this.currentEvalId = evalId;
            this.organizationId = orgId;
            this.setupAsyncTask(LoadModulesTask, () =>
                this.loadLastEval
                    ? this.loadFullEvalGraph(evalId, orgId, this.lastEvalRecord)
                          .then(() => {
                              this.loadLastEval = false;
                              successCallback && successCallback();
                          })
                          .catch((error) => {
                              errorCallback && errorCallback(error);
                          })
                    : this.loadFullEvalGraph(evalId, orgId)
                          .then(() => {
                              successCallback && successCallback();
                          })
                          .catch((error) => {
                              errorCallback && errorCallback(error);
                          }),
            );

            await delay(500);

            this.setupAsyncTask(LoadEvaluationDependenciesTask, () =>
                this.loadForOrganization(orgId),
            );

            this.setupAsyncTask(LoadAnswerHistory, () =>
                this.loadAnswerHistory(evalId),
            );
        }
    }

    private clearLocalStore = async () => {
        try {
            sessionStorage.clear();

            if (this.organizationId) {
                let keys = await this.undoLocalStorage.keys();

                keys = keys.filter((key) =>
                    key.includes(this.organizationId as string),
                );

                for (let key of keys) {
                    this.undoLocalStorage.removeItem(key);
                }
            }

            this.undoTracker = [];
        } finally {
            //no-op
        }
    };

    getModuleUIModel = computedFn(function (
        this: EvalStore,
        moduleId?: string,
    ) {
        if (moduleId) {
            return this.moduleUIModels.get(moduleId);
        }
    });

    @action
    setEvalAudioBuffer = (arg: {
        id: string;
        index: number;
        audioBuffer: AudioBuffer;
    }) => {
        this.evalAudioBuffer = arg.audioBuffer;
    };

    @action
    setCurrentModule = (module: string | LicensedModule) => {
        this.currentDynamicModule = undefined;
        if (typeof module === "string") {
            this.currentModule = this.licensedModulesForEval?.find(
                (value) => value.name === module,
            );
        } else {
            this.currentModule = module;
        }
    };

    @computed
    get isUndoAvailable() {
        const undoActions = Math.floor(this.undoTracker.length / 2);
        return undoActions >= 1;
    }

    @action
    private handleChange = async (arg: {
        serialized: string;
        evaluation?: Evaluation;
    }) => {
        const k = `${this.organizationId}|${Date.now().toString()}`;

        try {
            try {
                await this.undoLocalStorage.setItem(
                    k,
                    arg.serialized,
                    (err) => {
                        if (err) {
                            console.error(`IndexDB setItem error - ${err}`);
                        }
                    },
                );
                this.undoTracker.push(k);
            } catch (e1) {
                const oldestKey = this.undoTracker.shift();
                if (oldestKey) {
                    await this.undoLocalStorage.removeItem(oldestKey, (err) => {
                        if (err) {
                            console.error(`IndexDB removeItem error - ${err}`);
                        }
                    });

                    await this.undoLocalStorage.setItem(
                        k,
                        arg.serialized,
                        (err) => {
                            if (err) {
                                console.error(`IndexDB setItem error - ${err}`);
                            }
                        },
                    );
                    this.undoTracker.push(k);
                }
            }
        } catch (e2) {
            console.error(
                `sessionStorage failed to add latest undo-change graph: ${e2}`,
            );
        }
    };

    @action
    onTextMediaClipCreated = (
        text: string,
        startIndex: number,
        endIndex: number,
    ) => {
        if (text.length > 6000) {
            this.rootStore
                .getStore(MessageStore)
                .logError("Text clips may not exceed 6k characters");
            return;
        }

        if (!this.currentEval) return;

        const uniqueClipName = `Clip ${textClipTimestampDisplay(
            this.evalChat?.[startIndex].timestamp ?? "",
        )} - ${textClipTimestampDisplay(
            this.evalChat?.[endIndex].timestamp ?? "",
        )}`;

        const existingInactive = this.currentEval.soundClips?.find(
            (s) => s.segmentName === uniqueClipName && !s.isActive,
        );
        if (existingInactive) {
            existingInactive.isActive = true;
            return;
        }

        const soundClip = generateClipForEvaluation(
            this.currentEval,
            uniqueClipName,
            startIndex,
            endIndex,
            this.user?.profile.email,
        );

        soundClip.id = `temporary-clip-${Math.floor(
            Math.random() * 1000,
        )}-${Math.floor(Math.random() * 1000)}`;

        soundClip.transcriptionText = text;
        this.currentEval?.soundClips?.push(soundClip);

        this.setupAsyncTask(createAudioClipTask, () =>
            this.setupAsyncTask(`${createAudioClipTask}-${soundClip.id}`, () =>
                this.createClip(soundClip)
                    .then((clip) => {
                        if (clip) {
                            this.setOpenClipId(clip.id);
                            this.currentEval?.soundClips?.push(clip);
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                        this.rootStore
                            .getStore(MessageStore)
                            .logError(
                                "Error creating clip: " +
                                    (err as ServiceError).errorMessage,
                            );
                    })
                    .finally(() => {
                        const tempClipIdx =
                            this.currentEval?.soundClips?.findIndex(
                                (clip) => clip.id === soundClip.id,
                            ) ?? -1;
                        if (tempClipIdx > -1) {
                            this.currentEval?.soundClips?.splice(
                                tempClipIdx,
                                1,
                            );
                        }
                    }),
            )
                .then((res) => {
                    if (res === AsyncTaskStatus.Error) {
                        throw Error("Could not create clip");
                    }
                })
                .catch((reason) => {}),
        );
    };

    @action
    onAudioClipCreated = async (startTime: number, endTime: number) => {
        this.setOpenClipId(null);

        if (
            this.evalAudio &&
            this.currentEval &&
            !isNullableType(startTime) &&
            !isNullableType(endTime)
        ) {
            const uniqueClipName = `Clip ${soundClipDurationDisplay(
                startTime,
            )} - ${soundClipDurationDisplay(endTime)}`;

            const existingInactive = this.currentEval.soundClips?.find(
                (s) => s.segmentName === uniqueClipName && !s.isActive,
            );

            if (existingInactive) {
                existingInactive.isActive = true;
                return;
            }

            const soundClip = generateClipForEvaluation(
                this.currentEval,
                uniqueClipName,
                startTime,
                endTime,
                this.user?.profile.email,
            );
            soundClip.id = `temporary-clip-${Math.floor(
                Math.random() * 1000,
            )}-${Math.floor(Math.random() * 1000)}`;

            this.transcriptionForClip(soundClip);

            // Temporarily add clip to list while clip is generated on backend
            this.currentEval?.soundClips?.push(soundClip);

            this.setupAsyncTask(createAudioClipTask, () =>
                this.setupAsyncTask(
                    `${createAudioClipTask}-${soundClip.id}`,
                    () =>
                        this.createClip(soundClip)
                            .then((clip) => {
                                if (clip) {
                                    this.setOpenClipId(clip.id);
                                    this.currentEval?.soundClips?.push(clip);
                                }
                            })
                            .catch((err) => {
                                console.error(err);
                                this.rootStore
                                    .getStore(MessageStore)
                                    .logError(
                                        "Error creating clip: " +
                                            (err as ServiceError).errorMessage,
                                    );
                            })
                            .finally(() => {
                                const tempClipIdx =
                                    this.currentEval?.soundClips?.findIndex(
                                        (clip) => clip.id === soundClip.id,
                                    ) ?? -1;
                                if (tempClipIdx > -1) {
                                    this.currentEval?.soundClips?.splice(
                                        tempClipIdx,
                                        1,
                                    );
                                }
                            }),
                )
                    .then((res) => {
                        if (res === AsyncTaskStatus.Error) {
                            throw Error("Could not create clip");
                        }
                    })
                    .catch((reason) => {}),
            );
        }
    };

    getUniqueClipName(startTime: number, endTime: number): string {
        const uniqueClipName = `Clip ${soundClipDurationDisplay(
            startTime,
        )} - ${soundClipDurationDisplay(endTime)}`;
        return uniqueClipName;
    }

    @action
    onAudioClipUpdated = async (soundClip: PartialSegment) => {
        this.setOpenClipId(null);

        if (
            this.evalAudio &&
            this.currentEval &&
            !isNullableType(soundClip.startTime) &&
            !isNullableType(soundClip.endTime) &&
            !isNullableType(soundClip.id)
        ) {
            const uniqueClipName = this.getUniqueClipName(
                soundClip.startTime,
                soundClip.endTime,
            );

            const existingInactive = this.currentEval.soundClips?.find(
                (s) =>
                    s.startTime === soundClip.startTime &&
                    s.endTime === soundClip.endTime &&
                    !s.isActive,
            );

            if (existingInactive) {
                existingInactive.isActive = true;
                this.updateSoundClip(existingInactive).then((clip) => {
                    if (clip) {
                        this.setOpenClipId(clip.id);
                    }
                });
                return;
            }

            const existingClip = this.currentEval.soundClips?.find(
                (clip) => clip.id === soundClip.id,
            );

            if (!existingClip) {
                this.rootStore
                    .getStore(MessageStore)
                    .logWarning("Cannot find existing clip");
                return;
            }

            const timeRangeUpdated =
                soundClip.startTime !== existingClip.startTime ||
                soundClip.endTime !== existingClip.endTime;

            existingClip.segmentName =
                this.getUniqueClipName(
                    existingClip.startTime,
                    existingClip.endTime,
                ) === soundClip.labelText
                    ? uniqueClipName
                    : soundClip.labelText;
            existingClip.startTime = soundClip.startTime;
            existingClip.endTime = soundClip.endTime;

            if (timeRangeUpdated) {
                this.transcriptionForClip(existingClip);
            } else {
                existingClip.transcriptionText =
                    soundClip?.transcriptionText ??
                    existingClip.transcriptionText;
            }

            const currentIdx = this.currentEval.soundClips
                ?.map((clip) => clip.id)
                .indexOf(soundClip.id);
            if (currentIdx !== -1) {
                this.setupAsyncTask(UpdateAudioClip, () =>
                    this.setupAsyncTask(
                        `${UpdateAudioClip}-${existingClip.id}`,
                        () =>
                            this.updateSoundClip(existingClip).then((clip) => {
                                if (clip) {
                                    this.setOpenClipId(clip.id);
                                    if (timeRangeUpdated) {
                                        const messageStore =
                                            this.rootStore.getStore(
                                                MessageStore,
                                            );
                                        messageStore.showNextMessage();
                                        messageStore.logMessage(
                                            `"${clip.segmentName}" and transcript updated`,
                                            "success",
                                        );
                                    }
                                }
                                return clip;
                            }),
                    ),
                );
            }
        }
    };

    @action
    async createClip(clip: SoundClip) {
        if (!this.organizationId) {
            console.warn(
                "attempting to create clip with empty organization Id",
            );
            return;
        }

        let newClip;

        if (
            this.currentEval?.interaction?.interactionType ===
            InteractionType.Audio
        ) {
            var soundClipResponse = await this.soundClipService.createSoundClip(
                this.organizationId,
                clip,
            );

            newClip = soundClipResponse.soundClip;
            this.currentSoundClipMedia?.push({
                mediaUrl: soundClipResponse.mediaUrl,
                soundClipId: soundClipResponse.soundClip.id,
            } as SoundClipMedia);
        } else if (
            isTextMediaType(this.currentEval?.interaction?.interactionType)
        ) {
            newClip = await this.soundClipService.createTextClip(
                this.organizationId,
                clip,
            );
        }

        if (!newClip) {
            return;
        }

        const createdClip = SoundClip.fromJson(newClip);

        const found =
            this.currentEval?.soundClips?.findIndex(
                (value) => value.id === createdClip.id,
            ) ?? -1;

        if (found > -1) {
            const foundClip = this.currentEval?.soundClips?.[found];
            if (foundClip) {
                createdClip.note = foundClip.note;
                createdClip.tags = foundClip.tags;
                createdClip.transcriptionText = foundClip.transcriptionText;
                createdClip._tags = foundClip._tags;

                runInAction(() => {
                    this.currentEval?.soundClips?.splice(found, 1, createdClip);
                });
            }
        }

        return createdClip;
    }

    @action
    async updateSoundClip(clip: SoundClip) {
        if (!this.organizationId) {
            console.warn(
                "attempting to create clip with empty organization Id",
            );
            return;
        }

        const currentClip = this.currentSoundClipMedia?.find(
            (c) => c.soundClipId === clip.id,
        );

        if (!currentClip) {
            console.warn("could not find existing clip for update");
            return;
        }

        let updatedClip;

        if (
            this.currentEval?.interaction?.interactionType ===
            InteractionType.Audio
        ) {
            var soundClipResponse = await this.soundClipService.updateSoundClip(
                this.organizationId,
                clip,
            );

            updatedClip = soundClipResponse;
            this.currentSoundClipMedia?.push({
                mediaUrl: currentClip.mediaUrl,
                soundClipId: soundClipResponse.id,
            } as SoundClipMedia);
        }

        if (!updatedClip) {
            return;
        }

        const createdClip = SoundClip.fromJson(updatedClip);

        const found =
            this.currentEval?.soundClips?.findIndex(
                (value) => value.id === createdClip.id,
            ) ?? -1;

        if (found > -1) {
            const foundClip = this.currentEval?.soundClips?.[found];
            if (foundClip) {
                createdClip.note = foundClip.note;
                createdClip.tags = foundClip.tags;
                createdClip.transcriptionText = foundClip.transcriptionText;
                createdClip._tags = foundClip._tags;

                runInAction(() => {
                    this.currentEval?.soundClips?.splice(found, 1, createdClip);
                });
            }
        }

        return createdClip;
    }

    @action
    async deleteClip(clip?: SoundClip) {
        try {
            if (clip) {
                var isAudio =
                    this.currentEval?.interaction?.interactionType ===
                    InteractionType.Audio;

                await this.soundClipService.deleteClipById(clip.id, isAudio);

                if (isAudio) {
                    var indexToDelete =
                        this.currentSoundClipMedia?.findIndex(
                            (value) => value.soundClipId === clip.id,
                        ) ?? -1;

                    if (indexToDelete > -1) {
                        this.currentSoundClipMedia?.splice(indexToDelete, 1);
                    }
                }

                const found =
                    this.currentEval?.soundClips?.findIndex(
                        (value) => value.id === clip.id,
                    ) ?? -1;

                if (found > -1) {
                    this.currentEval?.soundClips?.splice(found, 1);
                }

                //this logic is for when a sound clip is deleted, the sound clip answer is dynamically removed
                this.currentEval?.answers.forEach((answer) => {
                    var soundClipAnswer = answer.soundClipAnswers.find(
                        (sca) => sca.soundClipId === clip.id,
                    ) as SoundClipAnswer;
                    if (soundClipAnswer !== undefined) {
                        var remainingAnswers = answer.soundClipAnswers
                            .filter((sc) => sc.soundClipId !== clip.id)
                            .map((sc) => sc.soundClip) as SoundClip[];
                        answer.setSoundClips(remainingAnswers);
                    }
                });
            }
        } catch (err: any) {
            this.rootStore
                .getStore(MessageStore)
                .logError("Failed to delete clip: " + JSON.stringify(err));
        }
    }

    async updateTextClip(clip: UpdateSoundClipRequest) {
        return this.setupAsyncTask<void>(`UpdateClip-${clip.id}`, () =>
            this.soundClipService.updateTextClip(clip),
        );
    }

    @action
    private transcriptionForClip(soundClip: SoundClip) {
        if (this.evalAudioTranscription === undefined) {
            return;
        }

        if (this.selectedTranscriptLanguage !== "en") {
            // Use english transcript from multiLang instead of original language
            const engTranscript = getTranscriptByLangCode(
                "en",
                this.evalMultiLangTranscriptions,
            );
            const avgUtterance =
                computeAverageUtteranceForTranscript(engTranscript);
            const txSegmentModels: ITranscriptionSegment[] =
                engTranscript ?? [];
            matchTranscriptionToSoundCLip(
                soundClip,
                txSegmentModels,
                avgUtterance,
            );
        } else {
            const avgUtterance = this.averageUtteranceDuration ?? 0.29;
            const txSegmentModels: ITranscriptionSegment[] =
                this.evalAudioTranscription ?? [];
            matchTranscriptionToSoundCLip(
                soundClip,
                txSegmentModels,
                avgUtterance,
            );
        }
    }

    @action
    setActiveClip = (id?: string, start?: number, end?: number) => {
        this.activeClip = this.currentEval?.soundClips?.find(
            (value) => value.id === id,
        );
    };

    @action
    setOpenClipId = (id?: string | null) => {
        if (id !== undefined) {
            this.openClipId = id;
        } else if (this.activeClip) {
            this.openClipId = this.activeClip.id;
        } else {
            this.openClipId = null;
        }
    };

    @computed
    get clipsToSegmentList(): PartialSegment[] {
        const activeUniqueSoundClips = _.uniqBy(
            this.currentEval?.activeSoundClips,
            (arg) => arg.id,
        );

        return activeUniqueSoundClips?.map((value) => {
            return {
                startTime: value.startTime ?? 0,
                endTime: value.endTime ?? 0,
                id: value.id,
                labelText: value.segmentName,
            };
        });
    }

    @action
    updateEval = async (
        nextState: EvaluationStatus,
        opts: { pendingAction?: boolean } = {},
    ) => {
        try {
            const res = await this.setupAsyncTask(
                UpdateEvaluationTask,
                () => this.updatEvalInternal(nextState, undefined, opts),
                true,
            );

            if (res === AsyncTaskStatus.Error) {
                return false;
            }

            return true;
        } catch (e) {
            // no -op
            return false;
        }
    };

    isRequiredQuestionsForWorkflowModule(evaluationModuleId: string): boolean {
        let requiredQuestions: EvalQuestion[];

        if (this.currentEval?.workflowInstances?.length) {
            requiredQuestions =
                this.licensedModulesForEval
                    ?.filter((value) => value.isActive)
                    ?.flatMap((value) => {
                        value.questions.forEach((q) => {
                            (q as EvalQuestion).evaluationModuleId =
                                value.evaluationModuleId;
                        });
                        return value.questions.filter((q) =>
                            shouldRender(
                                this.currentEval!.answers,
                                q as EvalQuestion,
                            ),
                        ) as EvalQuestion[];
                    })
                    .filter((q) => q.isActive)
                    .filter((value) => value.required) ?? [];

            var question = requiredQuestions.filter(
                (q) => q.evaluationModuleId === evaluationModuleId,
            );

            if (question) {
                for (let requiredQuestionGrouping of Object.entries<
                    Array<EvalQuestion>
                >(_.groupBy<EvalQuestion>(question, "id"))) {
                    let isFinished = true;
                    let requiredQuestionId = requiredQuestionGrouping[0];
                    let requiredQuestionsArray = requiredQuestionGrouping[1];

                    const answers = this.currentEval!.answers.filter(
                        (ans) =>
                            ans.questionId === requiredQuestionId &&
                            requiredQuestionsArray
                                .map((evalQ) => evalQ.evaluationModuleId)
                                .some(
                                    (arg) =>
                                        arg === ans.evaluationModuleId ||
                                        !ans.evaluationModuleId,
                                ),
                    );

                    if (answers.length < requiredQuestionsArray.length) {
                        isFinished = false;
                    }

                    for (let ans of answers) {
                        const question = requiredQuestions.find(
                            (q) => q.id === ans.questionId,
                        );
                        isFinished =
                            isFinished &&
                            ((ans?.answerTags.filter((v) => v.isActive)
                                .length ?? 0) > 0 ||
                                (question!.answerType.isFillInAnswer &&
                                    !!ans.fillInAnswerValue));
                    }

                    if (!isFinished) {
                        return true;
                    }
                }
            } else {
                return false;
            }
        }

        //no required questions were found
        return false;
    }

    @action
    saveEvalAsComplete = async (result?: string, notes?: string) => {
        const clearNotRenderedQuestions = (v: EvalLicensedModule) => {
            const notRenderedQuestions: EvalQuestion[] = v.questions.filter(
                (q: Question) =>
                    !shouldRender(this.currentEval!.answers, q as EvalQuestion),
            ) as EvalQuestion[];

            notRenderedQuestions.forEach((q: EvalQuestion) => {
                const questionAnswers = this.currentEval?.answers.filter(
                    (a: Answer) =>
                        a.questionId === q.id &&
                        (q.evaluationModuleId === a.evaluationModuleId ||
                            a.evaluationModuleId === undefined),
                );

                questionAnswers?.forEach((a: Answer) => {
                    a.fillInAnswerValue = null;
                    a.answerTags.forEach((ansTag: AnswerTag) => {
                        ansTag.isActive = false;
                    });
                    a.note = undefined;
                });
            });
        };

        const requiredQuestions: EvalQuestion[] =
            this.licensedModulesForEval
                ?.filter((v: EvalLicensedModule) =>
                    this.authStore.canUserView("Interaction Modules")
                        ? true
                        : v.renderPlacement !== RenderPlacement.Left,
                )
                ?.filter(
                    this.currentEval?.workflowInstances?.length
                        ? (v: EvalLicensedModule) => v.isActive
                        : (v: EvalLicensedModule) =>
                              !v.isWorkflowModule && v.isActive,
                )
                ?.flatMap((v: EvalLicensedModule) => {
                    v.questions.forEach((q: Question) => {
                        (q as EvalQuestion).evaluationModuleId =
                            v.evaluationModuleId;
                    });

                    clearNotRenderedQuestions(v);

                    const renderedQuestions: EvalQuestion[] =
                        v.questions.filter((q: Question) =>
                            shouldRender(
                                this.currentEval!.answers,
                                q as EvalQuestion,
                            ),
                        ) as EvalQuestion[];

                    return renderedQuestions;
                })
                .filter((q: EvalQuestion) => q.isActive)
                .filter((q: EvalQuestion) => q.required) ?? [];

        let isFinished = true;

        if (this.authStore.canUserView("Interaction Modules")) {
            if (!this.currentEval?.interaction?.customerTypeId) {
                this.rootStore
                    .getStore(MessageStore)
                    .logWarning("You Must Choose a Customer Type");
                return false;
            } else if (!this.currentEval?.interaction?.agentId) {
                this.rootStore
                    .getStore(MessageStore)
                    .logWarning("You Must Choose an Agent");
                return false;
            } else if (
                !this.currentEval?.interaction?.interactionDirection &&
                !this.currentEval?.interaction?.audioMetadata?.callDirection
            ) {
                this.rootStore
                    .getStore(MessageStore)
                    .logWarning("You Must Choose a Call Direction");
                return false;
            } else if (!this.currentEval?.interaction?.interactionDate) {
                this.rootStore
                    .getStore(MessageStore)
                    .logWarning("You Must Choose an Interaction Date");
                return false;
            }
        }

        if (!this.workflowStore.validate()) {
            this.rootStore
                .getStore(MessageStore)
                .logWarning("You Must Complete the Workflow Module");
            return false;
        }

        for (let requiredQuestionGrouping of Object.entries<
            Array<EvalQuestion>
        >(_.groupBy<EvalQuestion>(requiredQuestions, "id"))) {
            let requiredQuestionId = requiredQuestionGrouping[0];
            let requiredQuestionsArray = requiredQuestionGrouping[1];
            // questions in array can either be
            // assigned to a boss module instance for active workflow
            // assigned to boss module instance for another user
            // assigned to no boss module from legacy

            const answers = this.currentEval!.answers.filter(
                (ans) =>
                    ans.questionId === requiredQuestionId &&
                    requiredQuestionsArray
                        .map((evalQ) => evalQ.evaluationModuleId)
                        .some(
                            (arg) =>
                                arg === ans.evaluationModuleId ||
                                !ans.evaluationModuleId,
                        ),
            );

            if (answers.length < requiredQuestionsArray.length) {
                isFinished = false;
            }

            for (let ans of answers) {
                const question = requiredQuestions.find(
                    (q) => q.id === ans.questionId,
                );
                isFinished =
                    isFinished &&
                    ((ans?.answerTags.filter((v) => v.isActive).length ?? 0) >
                        0 ||
                        (question!.answerType.isFillInAnswer &&
                            !!ans.fillInAnswerValue));
            }

            if (!isFinished) {
                const firstMissingQuestion = requiredQuestionsArray[0];

                const moduleName = this.licensedModules.find(
                    (module) =>
                        module.id === firstMissingQuestion.licensedModuleId,
                )?.name;

                this.rootStore
                    .getStore(MessageStore)
                    .logWarning(
                        `The following required question from the module "${moduleName}" must be answered: ${firstMissingQuestion.questionText}`,
                    );
                break;
            }
        }

        if (!isFinished) {
            return false;
        }

        try {
            const taskRes = await this.setupAsyncTask(
                UpdateEvaluationTask,
                () =>
                    this.updatEvalInternal(
                        EvaluationStatus.Completed,
                        moment().local(),
                        {},
                        result,
                        notes,
                    ),
                true,
            );
            if (taskRes === AsyncTaskStatus.Error) {
                return false;
            }

            return true;
        } catch {
            return false;
        }
    };

    @action
    private async updatEvalInternal(
        nextState: EvaluationStatus,
        completionDate?: Moment | undefined,
        opts: { pendingAction?: boolean } = {},
        result?: string,
        notes?: string,
    ) {
        if (this.currentEval) {
            if (!this.currentEval.analystId && !this.user) {
                await this.loadUserProfile();
                if (!this.user) {
                    throw new Error(`Failed to load user profile`);
                }
            }

            this.currentEval.updateHeaderAnswers(
                this.licensedModulesForEval
                    ?.flatMap((value) => value.questions)
                    .filter(
                        (value) => value.type === QuestionType.QuestionHeading,
                    ) ?? [],
            );

            const currentEval = toJS(this.currentEval);

            currentEval.evaluationStatus = nextState;

            const actions = {
                all: currentEval.allActionThreads,
                latest: currentEval.actionThread,
            };

            // this is for all workflow conditions
            this.workflowStore.addIntermediateLevelToDisputeInstance(
                currentEval,
            );

            this.workflowStore.removeUncompletedNonBossModules(currentEval);
            this.workflowStore.completeBossLevels(currentEval);

            prepareEvalForSerialization(currentEval, this.user?.profile.email);

            currentEval.submittedDate =
                currentEval.submittedDate ?? serializeToUtc(completionDate);

            this.assignModuleScoresToModuleInstances(currentEval);

            const responseModel = {
                evaluation: currentEval,
                workflowResponse: {},
            } as EvaluationUpdateResponse;

            try {
                this.workRestorer.createRestorePoint(this.currentEval);

                const response: EvaluationRequestModel =
                    await this.evaluationService.updateEvalGraph(responseModel);

                this.lastEvalRecord = undefined;
                this.workRestorer.clearRestorePoint();

                if (!opts.pendingAction) {
                    response.evaluation.allActionThreads = actions.all;
                    if (!response.evaluation.actionThread) {
                        response.evaluation.actionThread = actions.latest;
                    }
                }

                await this.clearLocalStore();
                if (
                    response.evaluation.evaluationStatus ===
                        EvaluationStatus.InProgress ||
                    response.evaluation.evaluationStatus ===
                        EvaluationStatus.PendingReview
                )
                    await this.deserializeEvalJson(response.evaluation);

                if (response.errorCode) {
                    this.rootStore
                        .getStore(MessageStore)
                        .logWarning(response.errorCode);

                    return AsyncTaskStatus.Error;
                }
            } catch (e) {
                await this.workRestorer.onSaveFailure();
                throw e;
            }
        }
    }

    private assignModuleScoresToModuleInstances(evaluation: Evaluation) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (let [evalModuleId, model] of this.moduleUIModels) {
            let score = model.GenerateModuleScore();
            const evalModule = evaluation.evaluationModules.find(
                (v) => v.id === evalModuleId,
            );
            if (evalModule && score != null) {
                evalModule.moduleScore = score;
            }
        }
    }

    @action
    private async deserializeEvalJson(evaluationJson) {
        if (
            this.organizationId === evaluationJson.organizationId &&
            this.currentEvalId === evaluationJson.id
        ) {
            const updatedEval = Evaluation.fromJson(evaluationJson);
            runInAction(() => {
                this.currentEval = updatedEval;
                this.moduleUIModels.forEach((model) =>
                    model.updateEvaluation(updatedEval),
                );
                this.getClassifierResultValidation(this.currentEval);
            });
        }
    }

    @action
    setAudioVisualizerReady = (isSuccess: boolean) => {
        this.audioEditorStatus = isSuccess ? "Ok" : "Failed";
    };

    @computed
    get isMediaViewerDockOpen() {
        return Boolean(this.evalAudio || this.evalChat) && this.mediaDrawerOpen;
    }

    @action
    public async undoLastChange() {
        const rem = this.undoTracker.pop();

        try {
            if (rem) {
                await this.undoLocalStorage.removeItem(rem);
                const k = this.undoTracker.pop();
                if (k) {
                    const prev = (await this.undoLocalStorage.getItem(
                        k,
                        (err) => {
                            if (err) {
                                console.error(`IndexDB getItem error - ${err}`);
                            }
                        },
                    )) as string;
                    await this.undoLocalStorage.removeItem(k, (err) => {
                        if (err) {
                            console.error(`IndexDB removeItem error - ${err}`);
                        }
                    });
                    if (prev) {
                        this.currentEval = Evaluation.fromJson(
                            JSON.parse(prev),
                        );
                    }
                }
            }
        } catch (e) {
            console.error(`UndoLastChange Failed: ${e}`);
        }
    }

    @computed
    get evalAndDependenciesLoading() {
        return (
            this.getTaskLoading(LoadEvaluationDependenciesTask) ||
            this.getTaskLoading(LoadEvaluationTask) ||
            this.getTaskLoading(LoadModulesTask) ||
            this.evalAudioLoading ||
            this.getTaskLoading(LoadAnswerHistory)
        );
    }

    @action
    private async getModules(orgId: string, evaluationId?: string) {
        const modules = await this.moduleService.getAllLicensedModuleTypes(
            orgId,
            evaluationId,
        );

        runInAction(() => {
            this.licensedModules = modules.map((value) =>
                LicensedModule.fromJson(value),
            );
        });
    }

    @action
    async downloadClipFromBlob(filePath?: string) {
        if (filePath) {
            return await this.soundClipService.downloadAudioClipAsBlob(
                filePath,
                StorageAccountUseOptions.Main,
            );
        }
        return new Blob([], { type: "audio/wav" });
    }

    @action
    private async getClassifierResultValidation(evaluation: Evaluation) {
        const classifierValidations =
            await this.classifierService.getClassifierResultValidation(
                evaluation.id,
            );

        runInAction(() => {
            evaluation.classifierResultValidations = classifierValidations.map(
                (arg) => ClassifierResultValidation.fromJson(arg),
            );
        });
    }

    // @action
    // private async getAgents(orgId: string) {
    //     const agts = await this.agentService.getAgents(orgId);
    //     agts.unshift(EvalStore.createNewAgent);

    //     runInAction(() => {
    //         this.agentList = agts;
    //     });
    // }

    @action
    private async getCustomerTypes(orgId: string) {
        const custTypes = await this.customerTypesService.getCustomerTypes(
            orgId,
        );
        runInAction(() => {
            this.customerTypes = custTypes;
        });
    }

    @action
    setTags(tags: Tag[]) {
        this.tags = tags;
    }

    @computed
    get isReviewRequestDisabled() {
        const someTaskLoading =
            this.evalAndDependenciesLoading ||
            this.getTaskLoading(UpdateEvaluationTask) ||
            this.getTaskLoading(createAudioClipTask);

        const emptyEvalOrNonEditable =
            !this.currentEval || !this.currentEval?.isEditable;

        if (emptyEvalOrNonEditable) {
            return true;
        }

        return someTaskLoading;
    }

    @computed
    get areSaveActionsDisabled() {
        const someTaskLoading =
            this.evalAndDependenciesLoading ||
            this.getTaskLoading(UpdateEvaluationTask) ||
            this.getTaskLoading(createAudioClipTask);

        const emptyEvalOrNonEditable =
            !this.currentEval ||
            !(
                this.currentEval?.isEditable ||
                this.currentEval?.workflowInstances?.some(
                    (instance) =>
                        (instance as AssignedWorkflowInstance).assignedToUser &&
                        instance.isActive,
                )
            );

        if (emptyEvalOrNonEditable) {
            return true;
        }

        return Boolean(someTaskLoading);
    }

    @computed
    get showDisputeOption() {
        const noAgentEvalPerm =
            this.currentEval?.isDisputable &&
            this.areSaveActionsDisabled &&
            this.currentEval?.type !== EvalType.MiddleOfTheFunnel;

        if (!this.authStore.canUserView("Agent Acknowledge")) {
            return noAgentEvalPerm;
        } else {
            return (
                noAgentEvalPerm &&
                (!this.currentEval?.agentAcknowledged ||
                    this.currentEval?.previouslyDisputed)
            );
        }
    }
    @computed
    get showAcknowledgeOption() {
        return (
            this.authStore.isLoggedInUserAgent() &&
            this.authStore.canUserView("Agent Acknowledge") &&
            !(
                this.evalAndDependenciesLoading ||
                this.getTaskLoading(UpdateEvaluationTask) ||
                this.getTaskLoading(createAudioClipTask)
            ) &&
            !this.disputeHasStarted &&
            !this.currentEval?.previouslyDisputed &&
            this.currentEval?.evaluationStatus !== EvaluationStatus.Disputed &&
            this.currentEval?.type === EvalType.BottomOfTheFunnel
        );
    }

    public getMediaUrlFromSoundClipId(soundClipId: string) {
        var clipUrl = this.currentSoundClipMedia?.find(
            (media) => media.soundClipId === soundClipId,
        )?.mediaUrl;

        return clipUrl;
    }

    @action
    async generateRequestReviewAction(
        notes: string,
        reason: string,
        recipientIds: string[],
    ) {
        if (
            !this.organizationId ||
            !this.currentEval?.id ||
            !recipientIds?.length
        ) {
            return AsyncTaskStatus.Error;
        }

        const orgId = this.organizationId;
        const evalId = this.currentEval.id;
        const metadata = { notes, reason } as any;

        return this.setupAsyncTask(
            "Generating review request",
            () =>
                this.evalActionService.createRequestReviewAction(
                    orgId,
                    evalId,
                    undefined,
                    recipientIds,
                    metadata,
                ),
            true,
        );
    }

    @action
    async generateCompleteReviewAction(
        parentId: string,
        notes: string,
        reason: string,
    ) {
        if (
            !this.organizationId ||
            !this.currentEval?.id ||
            !parentId ||
            !reason
        ) {
            return AsyncTaskStatus.Error;
        }

        const recipientId = this.currentEval.analystId!;

        const orgId = this.organizationId;
        const evalId = this.currentEval.id;
        const metadata = { notes, reason } as any;

        return this.setupAsyncTask("Completing review request", () =>
            this.evalActionService.createCompleteReviewAction(
                orgId,
                evalId,
                parentId,
                recipientId,
                metadata,
            ),
        );
    }

    @action
    async replyToCompleteReviewAction(
        completedReview: Action<EvalReviewActionMetadata>,
        notes: string,
    ) {
        if (
            !this.organizationId ||
            !this.currentEval?.id ||
            !this.currentEval.actionThread ||
            !completedReview
        ) {
            return AsyncTaskStatus.Error;
        }

        const reason = completedReview.actionMetadata.parsedMetadata.notes;
        const parentId = completedReview.id;
        const recipientId = completedReview.userId;

        const orgId = this.organizationId;
        const evalId = this.currentEval.id;
        const metadata = { notes, reason } as any;

        return this.setupAsyncTask("Replying To Review", async () => {
            const res: Action<EvalReviewActionMetadata> =
                await this.evalActionService.replyToCompletedReviewAction(
                    orgId,
                    evalId,
                    parentId,
                    recipientId,
                    metadata,
                );

            runInAction(() => {
                setTimeout(() => {
                    if (this.currentEval) {
                        this.currentEval.actionThread = Action.fromJson(res);
                    }
                }, 240);
            });
            return true;
        });
    }

    @action
    generateEvaluationDisputeAction() {
        if (
            !this.organizationId ||
            !this.currentEval ||
            !this.currentEval.id ||
            !this.currentEval.disputedAnswers
        ) {
            this.rootStore
                .getStore(MessageStore)
                .logError("Disputed Answers are required.");
            return false;
        }

        return this.setupAsyncTask(GenerateDispute, async () => {
            await this.evaluationDisputeService.createEvaluationDispute(
                this.organizationId!,
                this.currentEval!.id,
                this.currentEval!.disputedAnswers,
            );

            this.rootStore
                .getStore(MessageStore)
                .logMessage(
                    "Your Dispute Request has been submitted for review.",
                    "success",
                );

            return true;
        });
    }

    @action
    setConvertToHumanModalOpen(open: boolean) {
        this.convertToHumanModalOpen = open;
    }

    @computed
    get showReviewDetailsCard() {
        const show =
            (!!this.currentEval?.actionThread ||
                !!this.currentEval?.allActionThreads) &&
            this.user?.profile.sub &&
            (this.showThread || this.showThreadHistory);
        const reviewProps = this.reviewDetailsCardProps;

        return Boolean(
            show && reviewProps.userId && !!reviewProps.threads?.length,
        );
    }

    @computed
    get reviewDetailsCardProps() {
        const threads =
            this.showThread && !!this.currentEval?.actionThread
                ? [this.currentEval.actionThread]
                : this.showThreadHistory && !!this.currentEval?.allActionThreads
                ? this.currentEval?.allActionThreads
                : undefined;

        const userId = this.user?.profile.sub;

        return { userId, threads };
    }

    @action dismissActionThread = () => {
        this.showThread = this.showThreadHistory = false;
    };

    @action
    refreshActionThread = async () => {
        if (!this.organizationId || !this.currentEval?.id) {
            return AsyncTaskStatus.Error;
        }

        const orgId = this.organizationId;
        const evalId = this.currentEval.id;

        if (!this.showThread && !!this.currentEval.actionThread) {
            this.showThreadHistory = false;
            this.showThread = true;
            return true;
        }

        return this.setupAsyncTask("Loading Latest Action Thread", async () => {
            const res: Action<EvalReviewActionMetadata> =
                await this.evalActionService.refreshActionThread(orgId, evalId);

            if (_.isEmpty(res)) {
                this.noActionsFoundDialogStore.open();
                this.noActionsFoundDialogStore.setTitle(
                    "No Review Actions Found",
                );

                this.noActionsFoundDialogStore.setErrorMessage(
                    "This evaluation currently has no active review action. click `Request Review` to start a request action and add to this evaluation's action history",
                );

                return true;
            }

            runInAction(() => {
                setTimeout(() => {
                    if (this.currentEval) {
                        this.currentEval.actionThread = res
                            ? Action.fromJson(res)
                            : undefined;

                        this.showThreadHistory = false;
                        this.showThread = !!this.currentEval.actionThread;
                    }
                }, 150);
            });
            return true;
        });
    };

    @action
    getAllActionThreads = async () => {
        if (!this.organizationId || !this.currentEval?.id) {
            return AsyncTaskStatus.Error;
        }

        const orgId = this.organizationId;
        const evalId = this.currentEval.id;

        if (!this.showThreadHistory && !!this.currentEval.allActionThreads) {
            this.showThread = false;
            this.showThreadHistory = true;
            return true;
        }

        return this.setupAsyncTask(
            "Loading Action Thread History",
            async () => {
                const res: Action<EvalReviewActionMetadata>[] =
                    await this.evalActionService.retrieveAllActionThreads(
                        orgId,
                        evalId,
                    );

                if (_.isEmpty(res) || res.length === 0) {
                    this.noActionsFoundDialogStore.open();
                    this.noActionsFoundDialogStore.setTitle(
                        "No Review Action History",
                    );

                    this.noActionsFoundDialogStore.setErrorMessage(
                        "This evaluation currently has no review action history. click `Request Review` to start a request action and add to this evaluation's action history",
                    );

                    return true;
                }

                runInAction(() => {
                    setTimeout(() => {
                        if (this.currentEval) {
                            if (
                                this.currentEval.actionThread &&
                                res.length === 1
                            ) {
                                this.currentEval.actionThread = res
                                    ? Action.fromJson(res[0])
                                    : undefined;

                                this.showThreadHistory = false;
                                this.showThread =
                                    !!this.currentEval.actionThread;
                            } else {
                                this.currentEval.allActionThreads = res
                                    ? res
                                          .map((value) =>
                                              Action.fromJson(value),
                                          )
                                          ?.sort((a, b) =>
                                              (a.createdOn ?? "") <
                                              (b.createdOn ?? "")
                                                  ? 1
                                                  : -1,
                                          )
                                    : undefined;

                                this.showThread = false;
                                this.showThreadHistory =
                                    !!this.currentEval.allActionThreads;
                            }
                        }
                    }, 150);
                });
                return true;
            },
        );
    };

    @action
    private async getEval(
        evalId: string,
        orgId: string,
        restorePoint?: EvaluationRequestModel, // i added this for demonstration
        evaluation?: Evaluation,
    ) {
        let request: EvaluationRequestModel;
        let evalGraph: Evaluation;

        if (!evaluation) {
            request =
                restorePoint ??
                (await this.evaluationService.getEvalGraph(orgId, evalId));

            evalGraph = Evaluation.fromJson(request.evaluation);
        } else {
            request = {
                evaluation,
                workflowMetadata: {},
                mediaUrl: "",
                soundClipMedia: [],
            };
            evalGraph = Evaluation.fromJson(request.evaluation);
        }

        if (this.authStore.orgStore.hasRedaction() === false) {
            this.setShowNotRedactedWarning(false);
        } else {
            this.setShowNotRedactedWarning(
                !(request.isRedacted !== undefined && request.isRedacted),
            );
        }

        if (
            (this.authStore.isLoggedInUserAgent() &&
                !this.authStore.isUserUltra()) ||
            evalGraph.evaluationStatus === EvaluationStatus.Enriching
        ) {
            evalGraph.isEditable = false;
        }
        // everything below needs to run whether loading from restore point or not

        const classifierValidationEnabled = Boolean(
            evalGraph.organization.licensedData.find(
                (value) =>
                    value.isActive &&
                    value.option?.processingOption ===
                        DataProcessingOptions.ClassifierValidation,
            ),
        );

        if (evalGraph.interaction?.organizationStructureMemberId) {
            const orgStructureMemberId =
                evalGraph.interaction?.organizationStructureMemberId;

            this.setupAsyncTask("Load Interaction Service Hierarchy", () =>
                this.organizationService
                    .getInteractionHierarchyLevels(orgStructureMemberId)
                    .then((value) => {
                        runInAction(() => {
                            this.interactionHierarchyLevels = value;
                        });
                    }),
            );
        }

        // if (evalGraph.interaction?.audioMetadataId) {
        //     const amdId = evalGraph.interaction.audioMetadataId;
        //     this.setupAsyncTask("Load Interaction Classifiers", () =>
        //         this.classifierService
        //             .positiveClassifications(amdId)
        //             .then((value) => {
        //                 runInAction(() => {
        //                     this.positiveClassifiers = value;
        //                 });
        //             }),
        //     );
        // }

        if (
            evalGraph.interaction?.audioMetadataId &&
            classifierValidationEnabled
        ) {
            const amdId = evalGraph.interaction.audioMetadataId;
            this.setupAsyncTask("Load All Interaction Classifiers", () =>
                this.classifierService
                    .allClassifications(amdId)
                    .then((value) => {
                        runInAction(() => {
                            this.allClassifiers = value;
                        });
                    }),
            );

            this.setupAsyncTask("Load Classifier Validation", () =>
                this.getClassifierResultValidation(evalGraph),
            );
        }

        const transcriptions =
            evalGraph.interaction?.audioMetadata?.transcriptions;
        if (evalGraph.interaction?.audioMetadata?.transcriptions) {
            evalGraph.interaction.audioMetadata.transcriptions = undefined;
        }

        runInAction(() => {
            this.showThread = !!evalGraph.actionThread;
            this.classifierValidationEnabled = classifierValidationEnabled;
            this.currentEval = evalGraph;
            this.currentMediaUrl = request.mediaUrl;
            this.currentSoundClipMedia = request.soundClipMedia;
            this.workflowStore.initialize(request.workflowMetadata);
            this.transcriptionModels = transcriptions;
            this.evaluationPermissions = request.permissions;
        });

        if (evalGraph.interaction?.interactionType === InteractionType.Audio) {
            runInAction(() => {
                this.evalAudio = this.audioClipForEval(evalGraph);

                this.setSoundClipEditorStore();
            });
        } else if (
            evalGraph.interaction?.interactionType ===
            InteractionType.ProcessedChat
        ) {
            this.setupAsyncTask("Load Chat Media File", async () => {
                if (evalGraph.interaction) {
                    const res = await this.chatMediaService.getChatMediaFile(
                        evalGraph.interaction?.audioMetadataId,
                    );

                    const interactionDate =
                        res.length > 0 ? res[0].timestamp : undefined;
                    if (!evalGraph.interaction.interactionDate) {
                        evalGraph.interaction.setInteractionDate(
                            interactionDate,
                        );
                    }

                    runInAction(() => {
                        this.evalChat = res;
                    });
                }
            });
        }
    }

    @action
    setSoundClipEditorStore = () => {
        this.soundClipEditorStore = new SoundClipEditorStore(
            "eval-sound-clip-editor",
            this.evalAudio,
            undefined,
            {
                alternateClipDownloader: this.redactedClipDownloader,
                onAudioBufferReady: this.setEvalAudioBuffer,
                onClipGenerated: this.onAudioClipCreated,
                onClipUpdated: this.onAudioClipUpdated,
                onVisualizerReady: this.setAudioVisualizerReady,
                segmentList: this.clipsToSegmentList,
                onSegmentMouseOver: this.setActiveClip,
                onSegmentClick: this.setOpenClipId,
                generateMp3: false,
                mediaUrl: this.currentMediaUrl,
                disableClip: false,
                colorClips: true,
            },
        );
    };

    @action
    setShowNotRedactedWarning = (show: boolean) => {
        this.showNotRedactedWarning = show;
    };

    @action
    setMediaDrawerSize = (width: number, height: number) => {
        this.mediaDrawerWidth = width;
        this.mediaDrawerHeight = height;
    };

    @action
    public toggleMediaViewerDrawer = () => {
        this.mediaDrawerOpen = !this.mediaDrawerOpen;
    };

    @action
    public closeMediaViewerDrawer = () => {
        this.mediaDrawerOpen = false;
    };

    private audioClipForEval(evaluation: Evaluation) {
        if (
            evaluation.interaction?.audioMetadata?.directory?.account &&
            evaluation.interaction?.interactionType === InteractionType.Audio
        ) {
            const sc = new ClipUIModel(uuid());

            const url = `https://${evaluation.interaction?.audioMetadata?.directory?.account}.blob.core.windows.net/${evaluation.interaction?.audioMetadata?.blobFileKey}`;
            sc.url = url;
            sc.segmentName = `${evaluation.qbAppId}`;
            sc.filePath = url;
            sc.startTime = 0;
            sc.organizationId = this.organizationId!;
            sc.storageAccount =
                evaluation.interaction?.audioMetadata?.directory?.account!;
            sc.directoryId =
                evaluation.interaction?.audioMetadata?.directory?.id;
            sc.fileName = evaluation?.interaction?.audioMetadata?.fileName;
            sc.storageAccountUse = StorageAccountUseOptions.Main;

            const redactionEnabled = Boolean(
                evaluation.organization.licensedData.find(
                    (value) =>
                        value.isActive &&
                        value.option?.processingOption ===
                            DataProcessingOptions.Redaction,
                ),
            );

            if (
                redactionEnabled &&
                evaluation.interaction.audioMetadataId &&
                (this.transcriptionModels?.length ?? 0) > 0
            ) {
                const amdId = evaluation.interaction.audioMetadataId;
                this.redactedClipDownloader = () =>
                    this.audioFilesService.getRedactedAudioFile(amdId);
            }

            return sc;
        }
        return undefined;
    }

    @action
    loadAnswerHistory = async (evalId) => {
        this.evalAnswerHistory =
            await this.evaluationService.getAnswerHistoryForEval(evalId);
    };

    @action
    public toggleAnswerHistory = async () => {
        if (this.drawerStore.isOpen) {
            this.drawerStore.closeDrawer();
        } else {
            this.drawerStore.closeAndResetDrawer();
            this.drawerStore.restoreDefaults();
            this.drawerStore.setContentFactory(() => (
                <AnswerHistoryView
                    evalAnswerHistory={this.evalAnswerHistory}
                    licensedModules={this.licensedModules}
                />
            ));

            this.drawerStore.setOpen(true);
        }
    };

    @action
    reassignEvaluationHierarchy() {
        this.setupAsyncTask("Reassign Hier", async () => {
            if (this.currentEvalId) {
                this.reassignHierarchyDialogStore.setLoading();
                try {
                    await this.hierarchyService.updateHierarchyForEval(
                        this.currentEvalId,
                        this.selectedReassignHierarchyId,
                    );

                    return await this.organizationService
                        .getInteractionHierarchyLevels(
                            this.selectedReassignHierarchyId,
                        )
                        .then((value) => {
                            runInAction(() => {
                                this.interactionHierarchyLevels = value;
                            });
                        });
                } catch (e) {
                    return new Promise(() => "No Id");
                } finally {
                    if (this.currentEval?.interaction) {
                        this.currentEval.interaction.organizationStructureMemberId =
                            this.selectedReassignHierarchyId;
                    }
                    this.reassignHierarchyDialogStore.setNotLoading();
                    this.reassignHierarchyDialogStore.close();
                }
            } else {
                return new Promise(() => "No Id");
            }
        });
    }

    @action
    setSelectedReassignHierarchy(hierId: string) {
        this.selectedReassignHierarchyId = hierId;
    }

    @action
    changeTranscriptLanguage(
        languageCode: IMultiLanguageTranscriptionPayload["languageCode"],
    ) {
        this.transcriptionData = this.evalMultiLangTranscriptions?.find(
            (item) => item.languageCode === languageCode,
        )?.transcription;
        this.selectedTranscriptLanguage = languageCode;
    }

    @action
    async convertToAnalystEvaluation(
        minimal: boolean,
        analyst: ApplicationUser | undefined,
    ) {
        await this.setupAsyncTask(ConvertToHumanEval, async () => {
            if (this.currentEvalId && this.organizationId) {
                try {
                    await this.evaluationService.convertToAnalystEvaluation(
                        this.currentEvalId,
                        this.organizationId,
                        analyst ? (analyst.id as string) : "",
                        minimal,
                    );
                } catch (e) {
                    this.rootStore
                        .getStore(MessageStore)
                        .logError("Failed to convert to analyst evaluation");
                }
            }
        });
    }

    @action
    async getAnalystOptions() {
        if (
            this.user?.profile.sub &&
            this.authStore.orgStore.selectedOrganization?.id
        ) {
            const analysts = await this.organizationService.getTeamAnalysts(
                this.authStore.orgStore.selectedOrganization.id,
                this.user.profile.sub,
            );
            analysts.sort((a, b) => {
                const x = `${a.firstName}${a.lastName}`;
                const y = `${b.firstName}${b.lastName}`;
                return x > y ? 1 : 0;
            });
            this.analystOptions = analysts;
        }
    }

    @action
    public async getHighlightsForTag(tagId: string, amdId: string) {
        try {
            let highlightsTagResponse =
                await this.evaluationService.getHighlightsForTag(tagId, amdId);
            this.formatAndSetStructuredHighlights(highlightsTagResponse);
        } catch (error) {
            throw error;
        }
    }

    @action
    formatAndSetStructuredHighlights(highlightTagRes: HighlightsForTagResults) {
        if (highlightTagRes.predictorType !== "Lucene") {
            return;
        }
        let speakers: { [key: string]: string } = {};

        const splitHighlightsArr = highlightTagRes.highlights
            ?.split("/n")
            .filter((message) => message.length);

        const aiSpeakerHighlights: AISpeakerHighlight[] =
            splitHighlightsArr?.map((message, index) => {
                const [speaker, ...messageParts] = message.split(": ");
                const speakerTitle =
                    this.convertSpeakerTitleInHighlights(speaker);
                const formattedHighlightText = messageParts.join(": ");
                const wordsFromHighlightText: {
                    phrase: string;
                    count: number;
                }[] = [];
                if (!speakers[speakerTitle]) {
                    speakers[speakerTitle] = getSpeakerColorByChannelOrPersona(
                        this.getSpeakerChannelOrChatPersona(speaker),
                    );
                }
                const capitalize = (str: string) => {
                    return str[0].toUpperCase() + str.slice(1).toLowerCase();
                };
                const rbcRegex = /<b>(.*?)<\/b>/g;
                let match = message.match(rbcRegex);
                match?.forEach((m) => {
                    const phraseCleaned = capitalize(
                        m.replace("<b>", "").replace("</b>", ""),
                    );
                    const found = _.findIndex(
                        wordsFromHighlightText,
                        (e) => phraseCleaned === e.phrase,
                    );
                    if (
                        found !== -1 &&
                        wordsFromHighlightText?.[found]?.count
                    ) {
                        wordsFromHighlightText[found].count =
                            wordsFromHighlightText[found].count + 1;
                    } else {
                        wordsFromHighlightText.push({
                            phrase: phraseCleaned,
                            count: 1,
                        });
                    }
                });
                return {
                    speaker: speakerTitle,
                    highlightText: formattedHighlightText,
                    color: speakers[speakerTitle],
                    wordsForRbc: wordsFromHighlightText,
                };
            });

        this.setStructuredHighlights(highlightTagRes.tagId, {
            aiSpeakerHighlights,
            ...highlightTagRes,
        });
    }

    @action
    setStructuredHighlights = (
        tagId: string,
        structuredHighlights: StructuredHighlights,
    ) => {
        this.structuredHighlights.set(tagId, structuredHighlights);
    };

    @action
    setShowAIExplanation(questionId: string, showExplanation: boolean) {
        this.showAIExplanation[questionId] = showExplanation;
    }

    @action
    setExplanationLoadingStates(tagId: string, isLoading: boolean) {
        this.explanationLoadingStates[tagId] = isLoading;
    }

    @action
    setShowModifiedWarning(showWarning: boolean) {
        this.showModifiedWarning = showWarning;
    }

    @computed
    get spanishTranscript(): ITranscriptionSegment[] | undefined {
        return getTranscriptByLangCode("es", this.evalMultiLangTranscriptions);
    }

    @computed
    get mediaType() {
        return this.currentEval?.interaction?.interactionType;
    }

    private getSpeakerChannelOrChatPersona(speaker: string): Speaker {
        const speakerIndex = Number(speaker.split(" ")[1]);
        switch (speakerIndex) {
            case 0:
                if (this.evalChat) {
                    return ChatPersona.Agent;
                } else {
                    return speakerIndex;
                }
            case 1:
                if (this.evalChat) {
                    return ChatPersona.Customer;
                } else {
                    return speakerIndex;
                }
            default:
                return speakerIndex;
        }
    }

    private convertSpeakerTitleInHighlights(speaker: string): string {
        switch (speaker) {
            case "Speaker 0":
                if (this.evalChat) {
                    return "Agent";
                } else {
                    return "Speaker 1";
                }
            case "Speaker 1":
                if (this.evalChat) {
                    return "Customer";
                } else {
                    return "Speaker 2";
                }
            default:
                if (speaker.includes("Speaker ")) {
                    const [, speakerNumber] = speaker.split(" ");
                    return `Speaker ${Number(speakerNumber) + 1}`;
                } else {
                    return "Speaker";
                }
        }
    }

    acknowledgeEvaluation = () => {
        if (this.currentEvalId) {
            try {
                this.evaluationService.acknowledgeEvaluation(
                    this.currentEvalId,
                );
                if (this.currentEval) {
                    this.currentEval.agentAcknowledged = true;
                }
                this.rootStore
                    .getStore(MessageStore)
                    .logMessage(
                        `Evaluation ${this.currentEval?.evaluationQbId} Acknowledged`,
                        "success",
                    );
            } catch (e) {
                this.rootStore
                    .getStore(MessageStore)
                    .logMessage("Failed to acknowledge evaluation.", "error");
            }
        }
    };

    @computed
    get currentEvalVisibleWorkflowInstances() {
        return (
            (this.currentEval?.workflowInstances ??
                []) as AssignedWorkflowInstance[]
        ).filter(
            (instance) =>
                instance.assignedToUser ||
                this.authStore.canUserEdit("Workflows"),
        );
    }
}
