import { Grid, Link } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
    GridColDef,
    GridComparatorFn,
    GridFilterInputValueProps,
    GridFilterItem,
    GridFilterOperator,
    GridRenderCellParams,
    GridSortCellParams,
} from "@mui/x-data-grid-pro";
import { Routes } from "components/Navigation/Routes";
import AcxMainTextField from "components/UI/AcxMainTextFieldGrid";
import _ from "lodash";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { DefaultFilter } from "./DefaultFormatters";
import theme from "Theme/AppTheme";

const getStyles = makeStyles(() => ({
    link: {
        "&:hover": {
            fontWeight: "bolder",
        },
    },
}));

export const evalNameFromBlobFileKey = (blobFileKey?: string) => {
    if (!blobFileKey) {
        return "";
    }

    let endPos = Math.min(
        Math.max(
            blobFileKey.lastIndexOf(".acxMedia"),
            blobFileKey.lastIndexOf(".audio"),
        ),
        blobFileKey.lastIndexOf(".__ACX"),
    );
    if (endPos < 1) {
        endPos = blobFileKey.length + 1;
    }

    const name = blobFileKey.substring(
        blobFileKey.lastIndexOf("/") + 1,
        endPos,
    );
    return name;
};

export const LinkFormatter = (obj: {
    id: string;
    blobfilekey?: string;
    name?: string;
    orgId?: string;
    orgIdValue?: string;
    evalId: string;
}) => {
    const fn = (params: GridRenderCellParams) => {
        const flds = obj;

        const id = params.row[flds.id]?.toString();
        const orgId = flds.orgId
            ? params.row[flds.orgId]?.toString()
            : flds.orgIdValue;
        const evalId = params.row[flds.evalId]?.toString();
        const evalNumber = params.row?.evaluationNumber;

        let evalName;
        if (flds.blobfilekey) {
            const blobFileKey = params.row[flds.blobfilekey!]?.toString();
            evalName = evalNameFromBlobFileKey(blobFileKey);
        } else {
            evalName = params.row[flds.name!]?.toString();
        }

        if (
            evalName === "" ||
            evalName === " " ||
            evalName === undefined ||
            evalName === null
        ) {
            evalName = evalNumber ?? "Link to Evaluation";
        }

        const classes = getStyles();

        const res = (
            <Grid
                container
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
            >
                <Grid item>
                    <Link
                        variant="body1"
                        key={`report-item-${id}`}
                        className={classes.link}
                        style={{
                            textAlign: "left",
                            color: theme.palette.neutral[600],
                            textDecoration: "underline",
                            textUnderlineOffset: theme.spacing(0.5),
                        }}
                        color="textPrimary"
                        component={RouterLink}
                        to={Routes.makeEvaluationRoute(
                            orgId ?? "",
                            evalId ?? "",
                        )}
                    >
                        {evalName}
                    </Link>
                </Grid>
            </Grid>
        );

        return res;
    };
    return fn;
};

export const getLinkFormatterOperators: (
    filterField: string,
) => GridFilterOperator[] = (filterField: string) => [
    {
        label: "contains",
        value: "contains",
        getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
            if (
                !filterItem.field ||
                !filterItem.value ||
                !filterItem.operator
            ) {
                return null;
            }

            const filterRegex = new RegExp(filterItem.value, "i");
            return (params): boolean => {
                const rowValue = _.get(params.row, filterField);
                return filterRegex.test(rowValue?.toString() || "");
            };
        },
        InputComponent: DefaultFilter,
    },
    {
        label: "equals",
        value: "equals",
        getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
            if (
                !filterItem.field ||
                !filterItem.value ||
                !filterItem.operator
            ) {
                return null;
            }
            return (params): boolean => {
                const rowValue = _.get(params.row, filterField);

                return (
                    filterItem.value?.localeCompare(
                        rowValue?.toString() || "",
                        undefined,
                        {
                            sensitivity: "base",
                        },
                    ) === 0
                );
            };
        },
        InputComponent: DefaultFilter,
    },
    {
        label: "startsWith",
        value: "startsWith",
        getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
            if (
                !filterItem.field ||
                !filterItem.value ||
                !filterItem.operator
            ) {
                return null;
            }

            const filterRegex = new RegExp(`^${filterItem.value}.*$`, "i");
            return (params): boolean => {
                const rowValue = _.get(params.row, filterField);
                return filterRegex.test(rowValue?.toString() || "");
            };
        },
        InputComponent: DefaultFilter,
    },
    {
        label: "endsWith",
        value: "endsWith",
        getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
            if (
                !filterItem.field ||
                !filterItem.value ||
                !filterItem.operator
            ) {
                return null;
            }

            const filterRegex = new RegExp(`.*${filterItem.value}$`, "i");
            return (params): boolean => {
                const rowValue = _.get(params.row, filterField);
                return filterRegex.test(rowValue?.toString() || "");
            };
        },
        InputComponent: DefaultFilter,
    },
];

export const LinkFormatterFilter = (props: GridFilterInputValueProps) => {
    const { item, applyValue } = props;
    const [value, setValue] = React.useState(item.value);

    const handleFilterChange = (event) => {
        setValue(event.target.value);
        applyValue({ ...item, value: event.target.value });
    };
    return (
        <AcxMainTextField
            id="link-filter-value"
            value={value}
            labelText="Search Value"
            onChange={handleFilterChange}
            textItemStyle={{ height: "32px" }}
        />
    );
};

export const LinkComparator = (filterFields: string): GridComparatorFn => {
    const fn: GridComparatorFn = (
        v1,
        v2,
        param1: GridSortCellParams,
        param2: GridSortCellParams,
    ): number => {
        const row1 = param1.api.getRow(param1.id);
        const row2 = param2.api.getRow(param2.id);

        let p1 = _.get(row1, filterFields)?.toString();
        let p2 = _.get(row2, filterFields)?.toString();

        if (!p1) return 1;
        if (!p2) return -1;

        const areNumeric = !isNaN(parseFloat(p1)) && !isNaN(parseFloat(p2));

        if (areNumeric) {
            return parseFloat(p1) - parseFloat(p2);
        }

        return p1.localeCompare(p2);
    };
    return fn;
};
