import React from "react";
import { Fade, Tooltip, Typography } from "@mui/material";
import { useStyles } from "./styles";
import Stack from "@mui/material/Stack";
import theme from "Theme/AppTheme";
import AcxButton from "../../../AcxButton";
import ScissorsSvg from "SvgIcons/ScissorsSvg";
import PlayControl from "components/AudioPlayer/PlayControl";
import { PlayStatus } from "../../../Audio/MicroPlayerControl";
import { PlayerOptions } from "components/SoundClipEditor/SoundClipEditor";
import EddyChip from "../../../Chat/Views/EddyChip";
import { useStore } from "utils/useStore";
import { AcxTranscriptionStore } from "../../Stores/AcxTranscriptionStore";
import { observer } from "mobx-react";
import parser from "html-react-parser";
import { BlurbBoxSpeakerTitle } from "./BlurbBoxSpeakerTitle";
import { Blurb } from "./Blurb";
import { Grid } from "@mui/material";
import { SpeakerStore } from "../../Stores/SpeakerStore/SpeakerStore";

export interface BlurbBoxProps {
    blurb: Blurb;
    index: number;
    playerBlurb?: (playerOptions: PlayerOptions) => void;
    onCreateClip: (timeInterval: number[]) => void;
    cancelHighlight: () => void;
    audioClip?: { status: "default" | "success" | "error" | "loading" };
    toolTipOpen?: boolean;
    hasEvaluation: boolean;
    speakerStore?: SpeakerStore;
    disableClip?: boolean;
}

const BlurbBox: React.FC<BlurbBoxProps> = observer((props) => {
    const {
        blurb,
        playerBlurb,
        onCreateClip,
        audioClip,
        toolTipOpen,
        cancelHighlight,
        hasEvaluation,
        index,
        speakerStore,
        disableClip,
    } = props;
    const classes = useStyles({ blurb });
    const store = useStore(AcxTranscriptionStore);

    const [selectedPlayingBlurb, setSelectedPlayingBlurb] = React.useState({
        id: "0",
        isPlaying: false,
    });

    const playAudio = () => {
        playerBlurb?.({
            id: blurb.timeInterval.join("-"),
            isPlaying: true,
            startTime: blurb.timeInterval[0],
            endTime: blurb.timeInterval[1],
        });
        setSelectedPlayingBlurb({
            id: blurb.timeInterval.join("-"),
            isPlaying: true,
        });
    };

    const pauseAudio = () => {
        playerBlurb?.({
            id: blurb.timeInterval.join("-"),
            isPlaying: false,
            startTime: blurb.timeInterval[0],
            endTime: blurb.timeInterval[1],
        });
        setSelectedPlayingBlurb({
            id: blurb.timeInterval.join("-"),
            isPlaying: false,
        });
    };

    const handleHighlightSelectionMouseDown = (blurb: Blurb, index: number) => {
        if (store.selectIndex !== -1 || !hasEvaluation) return;
        cancelHighlight();
        store.setHighlightedText([
            {
                ...blurb,
                startTime: blurb.timeInterval[0],
                endTime: blurb.timeInterval[1],
                index: index,
            },
        ]);
    };

    const handleHighlightSelectionMouseUp = (index: number) => {
        if (!hasEvaluation) return;
        for (let i = store.highlightedText[0]?.index; i < index + 1; i++) {
            store.blurbs.forEach((blurb, blurbIndex) => {
                if (blurbIndex === i) {
                    const isDuplicate = store.highlightedText.some(
                        (highlight) => highlight.index === blurbIndex,
                    );

                    if (!isDuplicate) {
                        store.setHighlightedText((prev) => {
                            const data = [
                                ...prev,
                                {
                                    ...blurb,
                                    text: `<mark>${blurb.text}</mark>`,
                                    startTime: blurb.timeInterval[0],
                                    endTime: blurb.timeInterval[1],
                                    index: blurbIndex,
                                },
                            ];
                            const sortedData = data.sort(
                                (a, b) => a.index - b.index,
                            );
                            store.selectIndex = blurbIndex;
                            return sortedData;
                        });
                    }
                }
            });
        }
    };

    const handleClip = () => {
        onCreateClip(blurb.timeInterval);
    };
    return (
        <Grid container className={classes.container} gap={3}>
            <Grid item xs={1}>
                <BlurbBoxSpeakerTitle
                    speakerStore={speakerStore}
                    blurb={blurb}
                />
            </Grid>
            <Grid item xs={10}>
                <Stack
                    direction={"row"}
                    onMouseDown={() => {
                        if (!disableClip) {
                            handleHighlightSelectionMouseDown(blurb, index);
                        }
                    }}
                    onMouseUp={() => {
                        if (!disableClip) {
                            handleHighlightSelectionMouseUp(index);
                        }
                    }}
                    onContextMenu={(e) => {
                        if (!disableClip) {
                            e.preventDefault();
                            handleHighlightSelectionMouseDown(blurb, index);
                            store.selectIndex = hasEvaluation ? index : -1;
                        }
                    }}
                >
                    <Typography className={classes.content} component="div">
                        <Tooltip
                            title={
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    style={{ zIndex: 100 }}
                                >
                                    <AcxButton
                                        color="primary"
                                        onClick={handleClip}
                                        loading={
                                            audioClip?.status === "loading"
                                        }
                                    >
                                        Create a Clip
                                    </AcxButton>

                                    <AcxButton onClick={cancelHighlight}>
                                        Cancel
                                    </AcxButton>
                                </Stack>
                            }
                            open={toolTipOpen}
                            arrow
                            placement="bottom-start"
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 0 }}
                        >
                            <div id={blurb.timeInterval[0].toString()}>
                                {parser(
                                    store.formatTranscriptForHighlight(
                                        blurb.text,
                                        index,
                                        blurb.timeInterval,
                                    ),
                                )}
                            </div>
                        </Tooltip>

                        {props.blurb.foundEddy && (
                            <Grid style={{ paddingTop: theme.spacing(1) }}>
                                <EddyChip altText="Eddy Effect Signal" />
                            </Grid>
                        )}
                    </Typography>
                </Stack>
                <div className={classes.hiddenActions}>
                    <PlayControl
                        svgSize="small"
                        buttonStyle={{
                            minWidth: "40px",
                            width: "40px",
                            height: "40px",
                            display: "flex",
                            justifyContent: "center",
                            border: "solid 1px #E4E4E7",
                            backgroundColor: "transparent",
                            margin: 0,
                            borderRadius: "0",
                            marginRight: "4px",
                        }}
                        play={() => playAudio()}
                        pause={() => pauseAudio()}
                        status={
                            selectedPlayingBlurb.id ===
                                blurb.timeInterval.join("-") &&
                            selectedPlayingBlurb.isPlaying
                                ? PlayStatus.Play
                                : PlayStatus.Paused
                        }
                        color={"default"}
                    />
                    {hasEvaluation && !disableClip && (
                        <AcxButton
                            fullWidth
                            size="small"
                            style={{
                                minWidth: "40px",
                                width: "40px",
                                height: "40px",
                                display: "flex",
                                justifyContent: "center",
                                border: "solid 1px #E4E4E7",
                                backgroundColor: "transparent",
                                margin: 0,
                            }}
                            tooltip="Create a clip"
                            loading={audioClip?.status === "loading"}
                            onClick={handleClip}
                        >
                            <ScissorsSvg />
                        </AcxButton>
                    )}
                </div>
            </Grid>
        </Grid>
    );
});

export default BlurbBox;
