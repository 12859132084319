import { EvalType } from "components/Conversations/Stores/ConversationsStore";
import BaseService from "./BaseService";
import { ITranscriptionPayload } from "./TranscriptionService";
import { IChatRecord } from "components/UI/Chat/AcxChatViewer";
import SoundClip from "models/SoundClip";
import { InteractionType } from "models/InteractionType";
import { ApplicationFilters } from "stores/ApplicationFilters/ApplicationFiltersStore";
import Conversation from "models/Conversation";
import { IRBCFilter } from "components/Conversations/Views/Drawer/components/DeepFilter/RBCFilterStore";

export interface ConversationData {
    callDurationMillis: number;
    chatTranscription: IChatRecord[];
    conversationClassifiers: ConversationClassifier[];
    conversationId: string;
    evalType: EvalType | null;
    mediaType: InteractionType;
    mediaUrl: string;
    summary: string;
    timestamp: string;
    transcriptions: ITranscriptionPayload[];
    agentFirstName: string;
    agentLastName: string;
    evaluationId: string;
    hasTranslation: boolean;
    isRedacted: boolean;
    highlights: Highlight;
}

export interface ConversationSoundClip {
    soundClip: SoundClip;
    montageIds: string[] | null;
    mediaUrl?: string;
}

export type Highlight = {
    audioMetadataId: string;
    fullTextSearchHighlightsSpeaker0: string | null;
    fullTextSearchHighlightsSpeaker1: string | null;
    fullTextSearchHighlightsSpeaker2: string | null;
    fullTextSearchHighlightsSpeaker3: string | null;
    rbcHighlightResults: IRBCHighlight[];
} | null;

export interface IRBCHighlight {
    classifierName: string;
    classifierId: string;
    speaker0Highlights: string | null;
    speaker1Highlights: string | null;
    speaker2Highlights: string | null;
    speaker3Highlights: string | null;
}

export enum ConversationsSafetyEvent {
    SafetyEventNotIdentified = 0,
    SafetyEventIdentifiedAndNotAcknowledged = 1,
    SafetyEventIdentifiedAndAcknowledged = 2,
}

export interface ConversationClassifier {
    classifierId: string;
    conversationId: string;
    name: string;
    result: string;
    score: number;
    classifierType: "Lucene" | "Tensorflow";
    resultJson: string | null;
}

export interface ConversationsPayload {
    conversationResults: ConversationData[];
    estimatedCount: number;
    sqlQueries: string[] | undefined;
}

export interface VisibleEvalModule {
    id: string;
    name: string;
    moduleScore: number;
}

type ConversationsRequest = ApplicationFilters & {
    pageNumber: number;
    sortField?: "ArrivedOn" | "Timestamp" | null;
    sortDirection?: "asc" | "desc" | null;
    conversationIds?: string[];
};

type HighlightsRequest = {
    rbcFilterItems: IRBCFilter[];
    searchPhrase: string;
    conversationIds: string[];
};

export class ConversationService extends BaseService {
    protected urlBase = "api/Conversations";

    public async getConversations(
        request: ConversationsRequest,
    ): Promise<ConversationsPayload> {
        const urlParams = new URLSearchParams();
        const apiPath = "GetConversations";

        const res = await this.post<ConversationsPayload>(
            JSON.stringify(request),
            urlParams,
            apiPath,
        );

        return res;
    }

    public async getHighlights(
        request: HighlightsRequest,
    ): Promise<Highlight[]> {
        const urlParams = new URLSearchParams();
        const apiPath = "GetHighlights";

        const res = await this.post<Highlight[]>(
            JSON.stringify(request),
            urlParams,
            apiPath,
        );

        return res;
    }

    public async getConversation(
        conversationId: string,
        signal?: AbortSignal,
    ): Promise<Conversation> {
        const params = new URLSearchParams({
            conversationId,
        });

        const res = await this.get<Conversation>(
            params,
            "GetSingleConversationAsync",
            signal,
        );

        return res;
    }

    public async getConversationSoundClipByAudioMetadataId(
        audioMetadataId: string,
    ) {
        const res = await this.get<ConversationSoundClip[]>(
            new URLSearchParams({ audioMetadataId }),
            "GetSoundClipsAndMontageIdsByAudioMetadataId",
        );
        return res;
    }

    public async getVisibleEvalModulesForCompletedEvaluation(
        evaluationId: string,
    ) {
        const res = await this.get<VisibleEvalModule[]>(
            new URLSearchParams({ evaluationId }),
            "GetVisibleEvalModulesForCompletedEvaluation",
        );

        return res;
    }

    public async getScreenRecordings(amdId: string) {
        const res = await this.get<any>(
            new URLSearchParams({ amdId }),
            "GetScreenRecordings",
        );

        return res;
    }
}

// used to get error message for failing searchPhrases from getConversations
export function extractOtherDetails(errorMessage: string): string | null {
    const detailsKeyword = `"otherDetails":`;
    const innerKeyword = "inner";
    const parts = errorMessage.split(detailsKeyword);
    if (parts.length > 1) {
        const finalPart = parts[1].split(innerKeyword)[0];
        return finalPart.trim();
    }
    return "";
}
